const LayoutUtils = {
    /**
     * Retorna em px o espaço disponível para evitar rolagem vertical
     * @param {Array.<int>} alturasFixas 
     * @param {int} alturaTela 
     * @returns {string}
     */
    ObterAlturaDisponivelEmTela (alturasFixas, alturaTela) {
        var somaDasAlturas = alturasFixas.reduce((acc, value) => acc + value, 0);
        var alturaDisponivel = alturaTela - somaDasAlturas;
        return `${alturaDisponivel}px`
    }
}

export default LayoutUtils