import React from 'react';
import MasterPage from '../../../masterpages/authenticated';
import ContentBox from '../../../../components/ContentBox';
import Form from '../../../assets/formValidate';
import Select from '../../../components/form/SelectGroup';
import Input from '../../../components/form/InputGroup';
import CheckBox from '../../../components/form/CheckBox';
import EmailsService from 'services/EmailsService';
import checkUpnAvailability from '../../../actions/validation/checkUpnAvailability';
import Mask from 'utils/masks';
import AlterarSenha from '../componentes/AlterarSenha/index';
import ValidateCod from '../componentes/ValidarCodigoAssessor';
import { withRouter } from 'react-router-dom';
import { authContext } from '../../../adalConfig';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setLoadingItem,
  removeLoadingItem,
} from '../../../components/loading/loadingActions';
import {
  rdxClearUsers,
  rdxGetUserFields,
  rdxGetPerfis,
  rdxGetLinhaServico,
  rdxGetUser,
  rdxGetPerfisUsuarioSelecionado,
  rdxIncluirPerfilAssociado,
  rdxRemoverPerfilAssociado,
  rdxGetPlansRightsByEntitlementId,
  rdxGetAdditionalProductsRightByEntitlementId,
  rdxGetAccountById
} from '../redux/usuariosActions';
import postUsuarioObterProduto from '../../../actions/usuarios/postUsuarioObterProduto';
import getUsuarioStatusCode from 'EstruturaAntiga/actions/usuarios/getUsuarioStatusCode';
import { NotificationManager } from 'react-notifications';
import validateManagerProfileUnlink from '../../../actions/usuarios/validateManagerProfileUnlink';
import { FormHeader } from '../componentes/FormHeader';
import Timeline from '../../../components/timeline';
import Confirmation from '../componentes/confirmation';
import Concluded from '../componentes/concluded';
import NavigationButtons from '../componentes/navigationButtons';
import { profileNavGerenciamentoUsuarios, profilesV2 } from 'EstruturaAntiga/permissions/permissionsV2';
import TemAcessoAosProfiles from 'EstruturaAntiga/permissions/checkPermissionV2';
import { getFormId, getLabelCheckboxAssessor } from '../componentes/Uteis';
import verifyConsultantCode from 'EstruturaAntiga/actions/usuarios/verifyConsultantCode';
import CONSTANTES_AMBIENTE from 'constants/constantesAmbiente';

import {
  ContentWrapperStyle,
} from './styles';

import {
  PerfisDeAcesso
} from '../componentes/styles';
import ErroGestaoUsuario from '../ErroGestaoUsuario';
import LoadingShadow from 'EstruturaAntiga/components/loadingShadow';
import ModalGestaoUsuarioResponse from 'EstruturaAntiga/pages/ModalGestaoUsuarioResponse';
import UsuariosService from 'services/UsuariosService';
import AssessoresService from 'services/AssessoresService';


const estruturaProduto = {
  plano: 3,
  licenca: 1
}

class EditarUsuariosV2 extends Form {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      erroPerfisDeAcesso: false,
      statuscode: 0,
      checkPermissionButtons: false,
      timelinePosition: 0,
      accepted: true,
      isSubmit: false,
      formHeaders: {
        formName: '',
        tenant: '',
        formTitle: ''
      },
      loadingForm: false,
      loadingUserInfo: false,
      loadingAutorizacao: false,
      autorizado: true,
      userId: '',
      initialUserInfo: {},
      listaPerfis: [],
      searchAlternateEmail: false,
      isAssessorBeforeUpdate: false,
      name: "",
      controlAdvisorCod: true,
      messageAdvisorCod: "",
      advisorCodIsLoading: "",
      codassessor: "",
      perfil: "",
      listaProdutoComplementoExibicao: [],
      LicensePlanExibicao: "",
      tipoUsuario: "",
      create: false,
      direito: [],
      perfis: [],
      oferta: [],
      profiles: [],
      priceLevelCurrencySymbol: "",
      priceLevelAmount: 0,
      priceLevelCurrencyName: "",
      addonCurrencySymbol: "",
      addonAmount: 0,
      addonCurrencyName: "",
      usuario: "",
      showPerfisPortal: false,
      validaExclusaoPerfilDeAcessoLoading: false,
      validaExclusaoUsuarioLoading: false,
      erroIncluirPerfil: "",
      searchUpn: false,
      searchEmail: false,
      modalOpen: "",
      hasAcessor: false,
      editarResponse: [],
      editarErro: false,
      showLoadingShadow: false,
      formContent: {
        adx_organizationname: {
          nome: "Empresa",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        firstname: {
          nome: "Nome",
          somenteLeitura: false,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        lastname: {
          nome: "Sobrenome",
          somenteLeitura: false,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        axt_displayname365: {
          nome: "Nome de exibição",
          somenteLeitura: false,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        mobilephone: {
          nome: "Celular",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
          mask: "phone",
        },
        telephone1: {
          nome: "Telefone",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
          mask: "phone",
        },
        axt_extensionattribute1: {
          nome: "Tipo de usuário",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        axt_extensionattribute2: {},
        axt_extensionattribute3: {
          nome: "Razão Social",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        axt_extensionattribute4: {
          nome: "CNPJ",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        axt_extensionattribute5: {
          nome: "Código do Assessor",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        axt_extensionattribute6: {},
        axt_extensionattribute7: {},
        axt_extensionattribute8: {
          nome: "E-mail alternativo",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        axt_extensionattribute9: {},
        axt_extensionattribute10: {},
        axt_extensionattribute11: {},
        axt_extensionattribute12: {},
        axt_extensionattribute13: {},
        axt_extensionattribute14: {},
        axt_extensionattribute15: {},
        emailaddress2: {
          nome: "E-mail alternativo",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        department: {
          nome: "Departamento",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        jobtitle: {
          nome: "Cargo",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        _axt_servicebundle_value: {
          nome: "Plano de E-mail",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
          lista: [],
        },
        listProductAddons: {
          nome: "Produtos Opcionais",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
          lista: [],
        },
        axt_userprincipalname: {
          nome: "Login",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        SufixMail: {
          nome: "Domínio",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
        emailaddress1: {
          nome: "E-mail",
          somenteLeitura: true,
          visible: false,
          obrigatorio: false,
          tamanho: null,
        },
      },
      form: {
        UsuarioAcessor: false,
        adx_organizationname: "",
        axt_extensionattribute1: "",
        axt_extensionattribute3: "",
        axt_extensionattribute4: "",
        axt_extensionattribute5: "",
        axt_extensionattribute8: "",
        emailaddress2: "",
        firstname: "",
        lastname: "",
        axt_displayname365: "",
        mobilephone: "",
        telephone1: "",
        department: "",
        jobtitle: "",
        _axt_servicebundle_value: {},
        listaProdutoComplemento: [],
        axt_userprincipalname: "",
        emailaddress1: "",
        SufixMail: "",
        listaLinhaServico: "",
        listaPerfis: "",
        listaPerfisAtribuidos: []
      },
      validations: {
        UsuarioAcessor: {},
        adx_organizationname: {},
        axt_extensionattribute3: {},
        axt_extensionattribute4: {},
        firstname: {},
        lastname: {},
        axt_displayname365: {},
        mobilephone: { mask: "phone" },
        telephone1: { mask: "phone" },
        axt_extensionattribute8: { mask: "email" },
        emailaddress2: { mask: "email" },
        axt_extensionattribute5: {},
        department: {},
        jobtitle: {},
        _axt_servicebundle_value: {},
        listProductAddons: {},
        axt_extensionattribute1: {},
        axt_userprincipalname: { mask: "email" },
        emailaddress1: { mask: "email" },
        SufixMail: {},
        listaLinhaServico: {},
        listaPerfis: {},
      },
      hasError: {
        UsuarioAcessor: {},
        adx_organizationname: {},
        axt_extensionattribute3: {},
        axt_extensionattribute4: {},
        firstname: {},
        lastname: {},
        axt_displayname365: {},
        mobilephone: {},
        telephone1: {},
        axt_extensionattribute8: {},
        axt_extensionattribute5: {},
        emailaddress2: {},
        department: {},
        jobtitle: {},
        _axt_servicebundle_value: {},
        listProductAddons: {},
        axt_extensionattribute1: {},
        axt_userprincipalname: {},
        emailaddress1: {},
        SufixMail: {},
        listaLinhaServico: {},
        listaPerfis: {},
      },
    };

    this.onBlurAdvisorCode = this.onBlurAdvisorCode.bind(this);
    this.onConfirmModal = this.onConfirmModal.bind(this);
    this.renderNewUserPage = this.renderNewUserPage.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.onCloseModalValidEditUser = this.onCloseModalValidEditUser.bind(this);
  }

  renderNewUserPage() {
    this.setState({ loadingForm: true });
    return this.props.history.push(`/usuarios/novo`);
  }

  componentWillMount() {
    this.setState({ name: this.props.user.displayName });
  }

  componentDidMount = async () => {
    this.setState({
      loadingAutorizacao: true,
    });

    await this.permitirGestaoUsuario();

    const checkPermissionButtons = TemAcessoAosProfiles([profilesV2.admGlobal, profilesV2.GestorM365Gerenciado, profilesV2.GestorM365Basico]);

    this.setState({ checkPermissionButtons });

    const userId = this.props.user.contact && this.props.user.contact.id;

    if (!userId) return this.props.history.push(`/usuarios`);

    this.setState({
      userId: userId,
    });

    let upn = this.props.user.userPrincipalName;

    await this.props.rdxClearUsers(true);

    /* Busca dados do usuário */
    this.setState({
      loadingForm: true,
      loadingUserInfo: true
    });

    await this.props.rdxGetUser(userId);

    await this.getProduct(this.props.userInfo.assignedLicenses);

    await this.getStatusCode(userId);

    /* Inicial loading de página */
    /* Busca todas as propriedades dinamicas */
    this.props.rdxGetUserFields();

    /* Busca perfis de acesso do usuário */
    await this.props.rdxGetPerfisUsuarioSelecionado(userId);

    /* Busca dados do produto direto no dynamics */
    this.props.rdxGetPlansRightsByEntitlementId();

    /* Busca dados de complemento do produto direto no dynamics */
    this.props.rdxGetAdditionalProductsRightByEntitlementId();

    /* Se não existir UPN na url */

    this.setState({ UserPrincipalName: upn });

    this.props.rdxGetPerfis();
    this.props.rdxGetLinhaServico();

    /* Busca dados da empresa do tenant/contrato/direito em gestão */
    await this.props.rdxGetAccountById();


    /* Busca dados da empresa */
    let userSignature = JSON.parse(sessionStorage.getItem('signature'));
    let newValues = {
      adx_organizationname: userSignature.accountName || "",
      axt_extensionattribute3: this.props.account.axt_razaosocial || '',
      axt_extensionattribute4: this.props.account.axt_cnpjview || '',
      listaPerfisAtribuidos: this.props.listaPerfisAtribuidos || []
    };
    let newForm = { ...this.state.form, ...newValues };
    this.setState({ form: newForm });

    /* Verifica se usuário é gestor de cotrato da empresa */
    this.setState({
      showPerfisPortal: TemAcessoAosProfiles([
        profilesV2.admGlobal,
        ...profileNavGerenciamentoUsuarios,
      ]),
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      let newObj = {};
      let newRequiredObj = {};
      let newForm = { ...this.state.form };
      let listaPerfis = [];
      let editableFields = [
        "axt_displayname365",
        "axt_userprincipalname",
        "firstname",
        "lastname",
        "mobilephone",
        "telephone1",
        "axt_extensionattribute8",
        "axt_displayname365",
        "axt_extensionattribute5",
        "emailaddress2",
        "department",
        "jobtitle"
      ];

      /* Atualiza valor do formulário de acordo com seu carregamento */
      this.props.listaFormFields.forEach((el) => {
        let somenteLeitura =
          editableFields.indexOf(el.atributo) >= 0 ? el.somenteLeitura : true;
        let obrigatorio =
          editableFields.indexOf(el.atributo) >= 0
            ? !somenteLeitura && el.obrigatorio
            : false;

        let newValueState = {
          nome: el.nome,
          somenteLeitura: somenteLeitura,
          obrigatorio: obrigatorio,
          visible: true,
          tamanho: el.tamanho,
          lista: el.valores,
        };

        newObj[el.atributo] = this.state.formContent[el.atributo]
          ? Object.assign(this.state.formContent[el.atributo], newValueState)
          : newValueState;

        if (!somenteLeitura && el.obrigatorio) {
          newRequiredObj[el.atributo] = {
            ...this.state.validations[el.atributo],
            required: true,
          };
        }
      });

      if (
        this.state.formContent.SufixMail.visible &&
        !this.state.formContent.emailaddress1.visible
      ) {
        let user = authContext.getCachedUser(authContext.config.clientId).profile;
        let emailOrUpn = user.email || user.upn;
        let sufix =
          "@" + emailOrUpn.split("@")[1].toLowerCase().replace(/\s/g, "");

        newForm = {
          ...newForm,
          SufixMail: sufix,
        };

        newRequiredObj.emailaddress1.mask = "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+$";
      }

      /* Inclui os valores nos campos de acordo com os dados do usuário */
      let selectedsLines = this.props.listaPerfisAtribuidos.map(
        (e) => e.DireitoId
      );
      let listItemsFiltered = this.props.listaLinhaServico.filter(
        (e) => selectedsLines.indexOf(e.value) < 0
      );

      /* Regras para usuário assessor */
      if (newObj.axt_extensionattribute5 && newObj.axt_extensionattribute5.visible) {
        this.setState({
          hasAcessor: true,
        });
      }

      let licensePlanOptional =
        this.props.userInfo.axt_contact_products !== undefined &&
          this.props.userInfo.axt_contact_products !== null
          ? this.props.userInfo.axt_contact_products
          : [];

      if (
        newObj.axt_extensionattribute5 &&
        !this.props.userInfo.axt_extensionattribute5
      ) {
        newObj.axt_extensionattribute5.visible = false;
      }
      if (!this.props.userInfo.axt_extensionattribute5) {
        newRequiredObj["axt_extensionattribute5"] = {
          ...this.state.validations["axt_extensionattribute5"],
          required: false,
        };
      }

      const {
        firstname,
        lastname,
        axt_displayname365,
        mobilephone,
        telephone1,
        emailaddress1,
        axt_extensionattribute1,
        axt_extensionattribute5,
        axt_extensionattribute8,
        axt_userprincipalname,
        axt_contact_products,
        emailaddress2,
        department,
        jobtitle
      } = this.props.userInfo;

      newForm = {
        ...newForm,
        UsuarioAcessor: axt_extensionattribute5 ? true : false,
        firstname: firstname || '',
        lastname: lastname || '',
        axt_displayname365: axt_displayname365 || '',
        mobilephone: mobilephone || '',
        telephone1: telephone1 || '',
        axt_extensionattribute1: axt_extensionattribute1 || '',
        axt_extensionattribute8: axt_extensionattribute8
          ? axt_extensionattribute8
          : emailaddress2
            ? emailaddress2
            : '',
        emailaddress2: emailaddress2 || '',
        axt_extensionattribute5: axt_extensionattribute5 || '',
        department: department || '',
        axt_contact_products: axt_contact_products || [],
        jobtitle: jobtitle || '',
        axt_userprincipalname: axt_userprincipalname,
        emailaddress1: emailaddress1 || '',
        listaLinhaServico: listItemsFiltered.length
          ? listItemsFiltered[0].value
          : '',
        listaPerfis: this.props.listaPerfis.length
          ? CONSTANTES_AMBIENTE.PERFIL_ACESSO.USUARIO_FINAL
          : '',
      };

      listaPerfis = this.props.listaPerfis.length ? this.props.listaPerfis : [];

      if (newObj.axt_extensionattribute5 && newObj.axt_extensionattribute5.nome === 'Código do Assessor') {
        newObj.axt_extensionattribute1.obrigatorio = false;
        newObj.axt_extensionattribute1.visible = false;
        newObj.axt_extensionattribute1.somenteLeitura = true;

        listaPerfis = [{
          label: "Gestor de Contrato",
          value: 282390000
        }, {
          label: "Usuário Final",
          value: 282390008
        }]
      }

      const { tenantName } = JSON.parse(sessionStorage.getItem('signature'));

      this.setState({
        loadingForm: (this.props.listaFormFields[0] && this.props.listaFormFields[0].formName) || this.props.listaFormFields.length === 0 ? false : true,
        loadingUserInfo: !firstname || !emailaddress1,
        isAssessorBeforeUpdate: newForm.UsuarioAcessor,
        formContent: { ...this.state.formContent, ...newObj },
        validations: { ...this.state.validations, ...newRequiredObj },
        form: newForm,
        initialUserInfo: newForm,
        listaPerfis: listaPerfis,
        formHeaders: {
          formName: this.props.listaFormFields[0] && this.props.listaFormFields[0].formName,
          formTitle: this.state.name || 'Editar usuário',
          tenant: tenantName
        }
      });

      /* Trata dados de licença e licenças opcionais no carregamento do formulário*/
      if (this.props.userInfo._axt_servicebundle_value) {
        if (licensePlanOptional.length > 0) {
          var array = [];
          this.props.listaProdutoComplemento.map((item) => {
            licensePlanOptional.map((valor) => {
              if (item.value === valor.productid) {
                array.push(item);
              }
            });
          });

          if (array.length > 0) {
            const ammout = array
              .map((o) => o.amount)
              .reduce((a, c) => a + c, 0)
              .toFixed(2);

            const addonAmount = Number(ammout);
            const addonCurrencyName = array.map((o) => o.currencyName)[0];
            const addonCurrencySymbol = array.map((o) => o.currencySymbol)[0];

            this.setState({
              addonAmount,
              addonCurrencyName,
              listaProdutoComplementoExibicao: array,
              addonCurrencySymbol,
              form: {
                ...newForm,
                axt_contact_products: array.map((o) => o.value),
              },
            });
          }
        }

        if (licensePlan) {
          this.setState({
            LicensePlanExibicao: this.props.listaDePacotesCSP.length
              ? licensePlan.value +
              ";" +
              licensePlan.currencySymbol +
              ";" +
              licensePlan.amount +
              ";" +
              licensePlan.currencyName
              : "",
            priceLevelCurrencySymbol: this.props.listaDePacotesCSP.length
              ? licensePlan.currencySymbol
              : "",
            priceLevelAmount: this.props.listaDePacotesCSP.length
              ? licensePlan.amount
              : "",
            priceLevelCurrencyName: this.props.listaDePacotesCSP.length
              ? licensePlan.currencyName
              : "",
          });
        }
      }
    }
  }

  async permitirGestaoUsuario () {
    await UsuariosService.ValidarHabilitacaoGestaoUsuarios()
      .catch(() => {
        this.setState({autorizado: false});
      })
      .finally(() => {
        this.setState({loadingAutorizacao: false});
      });
  }	

  getStatusCode = async (id) => {
    const userStatusCode = await getUsuarioStatusCode(id);
    this.setState({ statuscode: userStatusCode.statuscode });
  }

  getProduct = async (assignedLicenses) => {
    const userProduct = await postUsuarioObterProduto(assignedLicenses);
    const emailPlan = userProduct.find(prod => prod.estruturaProduto === estruturaProduto.plano);
    const optionalLicenses = userProduct.filter(prod => prod.estruturaProduto === estruturaProduto.licenca);
    let newValues = {
      _axt_servicebundle_value: emailPlan ? { value: emailPlan?.produtoId, UnidadePrincipalId: emailPlan?.unidadeId } : {},
      listaProdutoComplemento: optionalLicenses.length ? optionalLicenses.map(addon => { return { value: addon?.produtoId, UnidadeId: addon?.unidadeId } }) : []
    };
    let newForm = { ...this.state.form, ...newValues };
    this.setState({ form: newForm });
  }

  async onBlurAdvisorCode(event) {
    const value = event.target.value;

    if (event.target.value) {
      this.setState({ codassessor: '', modalOpen: '', controlAdvisorCod: false, messageAdvisorCod: '' })

      this.setState({ advisorCodIsLoading: true });
      const isConsultantCode = getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.CONSULTORES_XP;
      const verificaCodigoAssessor = isConsultantCode ? await verifyConsultantCode(value) : await AssessoresService.ChecarCodigoAssessor(value);
      this.setState({ advisorCodIsLoading: '' });

      if (+verificaCodigoAssessor.resultCode === 1) {
        this.setState({ codassessor: '', modalOpen: '', controlAdvisorCod: true, messageAdvisorCod: '' });
      } else {
        this.setState({ codassessor: value, controlAdvisorCod: false, messageAdvisorCod: verificaCodigoAssessor.message });
      }

      if (+verificaCodigoAssessor.resultCode === 0) {
        this.setState({ modalOpen: "CodAssessor" });
      }
    }
  }

  renderField(obj, id, cols, customProps) {
    if (obj.visible || this.state.teste) {
      let configInput = [id];

      if (obj.mask) {
        configInput = [
          ...configInput,
          (el) => {
            el.target.value = Mask(el.target.value, obj.mask);
          },
        ];
      }

      let props = {
        id: id,
        label: obj.nome + (obj.obrigatorio ? "*" : ""),
        readOnly: obj.somenteLeitura,
        configureInput: this.configureInput(...configInput),
        items: obj.lista.length
          ? [
            { valor: "", label: "Selecione" },
            ...obj.lista.map((el) => {
              return { value: el.valor, label: el.textoExibicao };
            }),
          ]
          : [],
        ...customProps,
      };

      if (obj.tamanho) {
        props.maxLength = obj.tamanho;
      }

      if (obj.lista.length) {
        return (
          <div className={cols}>
            <Select {...props} />
          </div>
        );
      } else {
        if (id === "axt_extensionattribute5") {
          return (
            <div className={cols} onBlur={this.onBlurAdvisorCode}>
              <Input
                loading={this.state.advisorCodIsLoading}
                alertControlAdvisor={this.state.messageAdvisorCod}
                advisorControl={this.state.controlAdvisorCod}
                {...props}
              />
            </div>
          );
        } else if (id === "axt_extensionattribute8") {
          return (
            <div className={cols}>
              <Input tooltip={true} {...props} />
            </div>
          );
        } else {
          return (
            <div className={cols}>
              <Input {...props} />
            </div>
          );
        }
      }
    }

    return "";
  }

  formularioEhValido() {
    var errors = Object.entries(this.state.hasError);

    for (let index = 0; index < errors.length; index++) {
      let error = errors[index];

      if (Object.keys(error[1]).length > 0)
        return false;
    }

    return true;
  }

  getLinhaServico(obj) {
    let val = this.props.listaLinhaServico.filter(
      (el) => el.value === obj.DireitoId
    )[0];
    if (val) return val.label;
  }

  renderListaPerfisAcesso() {
    return (
      <div>
        <span className="title">Perfis associados ao usuário:</span>
        <div className="row results">
          {this.state.form.listaPerfisAtribuidos
            .filter(
              (obj) =>
                obj?.PerfilDeAcesso?.toString() !== "282390005" &&
                obj?.PerfilDeAcesso?.toString() !== "282390006" &&
                obj?.PerfilDeAcesso?.toString() !== "282390007"
            )
            .map((obj) => (
              <div
                key={obj?.DireitoId + obj?.PerfilDeAcesso}
                className="col-12 col-sm-6 col-lg-4 result"
              >
                <div className="container-result">
                  <div className="result-details">
                    <span className="label">Linha de serviço:</span>
                    <span className="value">
                      {obj?.Rotulo}
                    </span>
                    <span className="label">Perfil:</span>
                    <span className="value">{obj?.PerfilDeAcesso}</span>
                  </div>
                  <button
                    className="btn btn-danger"
                    onClick={() => this.onClickRemoveProfile(obj)}
                  >
                    Excluir
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    )

  }

  showProfiles() {
    if (
      !this.props.listaLinhaServicoLoading &&
      !this.props.listaPerfisLoading &&
      !this.props.listaPerfisAtribuidosLoading &&
      !this.state.validaExclusaoPerfilDeAcessoLoading
    ) {
      return (
        <div className="container-result">
          <div
            className={`container-publico-alvo ${this.state.form.listaPerfisAtribuidos.length === 0 ? "d-none" : ""
              }`}
          >
            {this.state.form.listaPerfisAtribuidos.length ? this.renderListaPerfisAcesso() : ''}
          </div>
          <span
            className={`no-results${this.state.form.listaPerfisAtribuidos.length > 0 ? " d-none" : ""
              }`}
          >
            Nenhum perfil associado
          </span>
        </div>
      );
    } else {
      return (
        <div className="block-loading">
          <div className="simple-loading"></div>
        </div>
      );
    }
  }

  renderPerfisDeAcesso() {
    if (this.state.showPerfisPortal) {
      let selectedsLines = this.state.form.listaPerfisAtribuidos.map(
        (e) => e.DireitoId
      );
      let listItemsFiltered = this.props.listaLinhaServico.filter(
        (e) => selectedsLines.indexOf(e.value) < 0
      );

      return (
        <PerfisDeAcesso erroPerfisDeAcesso={this.state.erroPerfisDeAcesso}>
          <div className="perfis">
            <h2>Perfis de acesso ao atendimento</h2>
            <ContentBox className="form-container">
              <div className="row">
                <div className="col-12 col-sm-6 col-lg-4">
                  <Select
                    label="Linha de serviço"
                    items={listItemsFiltered}
                    configureInput={this.configureInput("listaLinhaServico")}
                    loading={
                      this.props.listaLinhaServicoLoading ||
                      this.props.listaPerfisAtribuidosLoading
                    }
                    disabled={listItemsFiltered.length === 0}
                  />
                </div>
                <div className="col-12 col-sm-6 col-lg-4 col-xl-5">
                  <Select
                    label="Perfil"
                    items={this.state.listaPerfis}
                    configureInput={this.configureInput("listaPerfis")}
                    loading={this.props.listaPerfisLoading}
                    disabled={listItemsFiltered.length === 0}
                  />
                </div>
                <div className="col-12 col-lg-4 col-xl-3 add-publico-alvo">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={this.onClickAddProfile.bind(this)}
                    disabled={
                      this.props.listaLinhaServicoLoading ||
                      this.props.listaPerfisLoading ||
                      this.props.listaPerfisAtribuidosLoading ||
                      listItemsFiltered.length === 0
                    }
                  >
                    Adicionar perfil de acesso
                  </button>
                </div>
              </div>

              <span
                className={`error-duplicated ${this.state.erroIncluirPerfil ? "" : "d-none"
                  }`}
              >
                {this.state.erroIncluirPerfil}
              </span>

              {this.showProfiles()}
            </ContentBox>
          </div>
        </PerfisDeAcesso>
      );
    }
  }

  renderFieldCheckBoxAcessor() {
    const onChangeCheckbox = (e) => {
      this.setState({
        formContent: {
          ...this.state.formContent,
          axt_extensionattribute5: {
            ...this.state.formContent.axt_extensionattribute5,
            visible: e.target.checked,
          },
        },
        form: {
          ...this.state.form,
          UsuarioAcessor: e.target.checked,
        },
        validations: {
          ...this.state.validations,
          axt_extensionattribute5: {
            ...this.state.validations.axt_extensionattribute5,
            required: e.target.checked,
          },
        },
      });
    };

    if ((this.state.hasAcessor && this.state.isAssessorBeforeUpdate) || (this.state.hasAcessor && this.state.form.axt_extensionattribute5)) {
      return (
        <div className="col-12 col-lg-6">
          <div className="form-group form-group-checkbox">
            <CheckBox
              id="UsuarioAcessor"
              label={getLabelCheckboxAssessor()}
              checked={getFormId() == CONSTANTES_AMBIENTE.FORMULARIO_USUARIOS.CONSULTORES_XP ? true : this.state.form.UsuarioAcessor}
              onChange={onChangeCheckbox}
              readOnly
            />
          </div>
        </div>
      );
    } else if (this.state.hasAcessor) {
      return (
        <div className="col-12 col-lg-6">
          <div className="form-group form-group-checkbox">
            <CheckBox
              id="UsuarioAcessor"
              label="Usuário Assessor"
              checked={this.state.form.UsuarioAcessor}
              onChange={onChangeCheckbox}
            />
          </div>
        </div>
      );
    }
  }

  onConfirmModal() {
    this.setState({
      codassessor: "",
      modalOpen: "",
      controlAdvisorCod: true,
      messageAdvisorCod: "",
    });
  }

  onCloseModal() {
    this.setState({ modalOpen: "" });
  }

  onCloseModalValidEditUser() {
    this.setState({ modalOpen: "" })
  }

  renderModal() {
    if (this.state.modalOpen === "alterar-senha") {
      return (
        <AlterarSenha
          onCloseModal={this.onCloseModal.bind(this)}
          upn={this.state.UserPrincipalName}
          sync={this.props.userInfo.onPremisesSyncEnabled || false}
        />
      );
    } else if (this.state.modalOpen === "CodAssessor") {
      return (
        <ValidateCod
          onConfirmModal={this.onConfirmModal}
          onCloseModal={this.onCloseModal.bind(this)}
          cod={this.state.codassessor}
        ></ValidateCod>
      );
    } else if (this.state.modalOpen === 'ResultadoValidacaoEditar') {
      return (
        <ModalGestaoUsuarioResponse
          openModal={true}
          onCloseModal={this.onCloseModalValidEditUser.bind(this)}
          responseError={this.state.editarErro}
          response={this.state.editarResponse}
        />
      )
    }
  }

  async setlinhaServico() {
    let selectedsLines = this.state.form.listaPerfisAtribuidos.map(
      (e) => e.DireitoId
    );
    let listItemsFiltered = this.props.listaLinhaServico.filter(
      (e) => selectedsLines.indexOf(e.value) < 0
    );

    const newForm = {
      ...this.state.form,
      listaLinhaServico: listItemsFiltered.length
        ? listItemsFiltered[0].value
        : ''
    }

    this.setState({
      form: newForm
    });
  }

  async onClickAddProfile() {
    await this.setlinhaServico();

    let service = this.state.form.listaLinhaServico;
    let profile = this.state.form.listaPerfis;

    let direitoNovo = this.props.listaLinhaServico.filter(
      (e) => e.value == service
    )[0];

    let perfilAcessoNovo = this.props.listaPerfis.filter(
      (e) => e.value == profile
    )[0];

    let newValue = {
      PerfilDeAcesso: perfilAcessoNovo.label,
      PerfilDeAcessoCodigo: perfilAcessoNovo.value.toString(),
      DireitoId: direitoNovo.value,
      LinhaDeServico: direitoNovo.LinhaDeServico,
      Direito: direitoNovo.Direito,
      Rotulo: direitoNovo.label
    };

    let isDuplicated =
      this.state.form.listaPerfisAtribuidos.filter(
        (el) => el.DireitoId == newValue.DireitoId && el.PerfilDeAcessoCodigo == newValue.PerfilDeAcessoCodigo
      ).length > 0;

    if (!isDuplicated) {
      this.rdxIncluirPerfilAssociado(newValue);

      if (this.state.form.listaPerfisAtribuidos.length) {
        this.setState({
          validations: {
            ...this.state.validations,
            listaLinhaServico: { ...this.state.validations.listaLinhaServico, required: false, hasError: false },
            listaPerfis: { ...this.state.validations.listaPerfis, required: false, hasError: false }
          }
        });

        this.formValido(['UsuarioAcessor', 'listaLinhaServico', 'listaPerfis']);
      }

      this.setState({ erroIncluirPerfil: "", erroPerfisDeAcesso: false });
    } else {
      this.setState({ erroIncluirPerfil: "Perfil já adicionado." });
    }
  }

  rdxIncluirPerfilAssociado(obj) {
    let newValues = {
      listaPerfisAtribuidos: [
        ...this.state.form.listaPerfisAtribuidos,
        obj
      ]
    };
    let newForm = { ...this.state.form, ...newValues };
    this.setState({ form: newForm });
  }

  async removerPerfilAssociado(obj) {
    await this.rdxRemoverPerfilAssociado(obj);
    this.setState({ erroIncluirPerfil: "" });
    if (!this.state.form.listaPerfisAtribuidos.length) {
      this.setState({
        erroPerfisDeAcesso: true,
        validations: {
          ...this.state.validations,
          listaLinhaServico: { ...this.state.validations.listaLinhaServico, required: true, hasError: true, errorMensage: "Adicione pelo menos um perfil de acesso" },
          listaPerfis: { ...this.state.validations.listaPerfis, required: true, hasError: true, errorMensage: "Adicione pelo menos um perfil de acesso" }
        }
      });
    }
  }

  async rdxRemoverPerfilAssociado(obj) {
    let selectedsLines = this.state.form.listaPerfisAtribuidos.map(
      (e) => e.DireitoId
    );
    let listItemsFiltered = this.props.listaLinhaServico.filter(
      (e) => selectedsLines.indexOf(e.value) < 0
    );

    let newValues = {
      listaPerfisAtribuidos: this.state.form.listaPerfisAtribuidos.filter(
        e => JSON.stringify(e) !== JSON.stringify(obj)
      ),
      listaLinhaServico: listItemsFiltered.length
        ? listItemsFiltered[0].value
        : '',
      listaPerfis: this.props.listaPerfis.length
        ? CONSTANTES_AMBIENTE.PERFIL_ACESSO.USUARIO_FINAL
        : '',
    };
    let newForm = { ...this.state.form, ...newValues };
    this.setState({ form: newForm });
  }

  onClickRemoveProfile(obj) {
    if (obj.PerfilDeAcesso === profilesV2.gestorContrato.idAxtRole) {
      this.setState({ validaExclusaoPerfilDeAcessoLoading: true });
      const entitlementId = obj.DireitoId;
      const userMail = this.props.userInfo.mail;
      validateManagerProfileUnlink(userMail, entitlementId).then((result) => {
        this.setState({ validaExclusaoPerfilDeAcessoLoading: false });
        if (result.hasMoreThanOneManagerContracts) {
          this.removerPerfilAssociado(obj);
        } else {
          NotificationManager.error(
            `Defina outro usuário como Gestor de Contrato para a linha de serviço ${this.getLinhaServico(
              obj
            )} antes de prosseguir.`,
            "Não foi possível excluir este perfil de acesso.",
            9000
          );
        }
      });
    } else {
      this.removerPerfilAssociado(obj);
    }
  }

  onBlurAlternateEmail = async el => {
    el.persist();

    await this.setState({
      validations: {
        ...this.state.validations,
        axt_extensionattribute8: {
          ...this.state.validations.axt_extensionattribute8,
          hasError: false,
          showErrorCurrentItem: true
        },
        emailaddress2: {
          ...this.state.validations.emailaddress2,
          hasError: false,
          showErrorCurrentItem: true
        }
      }
    })

    if (this.validateInput(el)) {
      const { initialUserInfo } = this.state;
      if (initialUserInfo.emailaddress2 === el.target.value) return;

      this.setState({ searchAlternateEmail: true });

      await EmailsService.ValidarEmailAlternativo(`${el.target.value}`)
        .then(() => {
          this.setState({ searchAlternateEmail: false });
        })
        .catch((error) => {
          this.setState({
            searchAlternateEmail: false,
            validations: {
              ...this.state.validations,
              emailaddress2: {
                ...this.state.validations.emailaddress2,
                hasError: true,
                errorMensage: error.response?.data.erros[0]
              }
            }
          }, () => { this.validateInput(el) });
        });
    }
  }

  onBlurUpn = async el => {
    el.persist();

    const { initialUserInfo } = this.state;
    if (initialUserInfo.axt_userprincipalname === el.target.value) return;

    await this.setState({
      validations: {
        ...this.state.validations,
        axt_userprincipalname: {
          ...this.state.validations.axt_userprincipalname,
          hasError: false,
          showErrorCurrentItem: true
        }
      }
    });

    if (this.validateInput(el)) {
      let errorText = "";
      this.setState({ searchUpn: true })

      const check = await checkUpnAvailability(el.target.value);

      this.setState({ searchUpn: false });

      if (Number(check.resultcode) === -1) {
        errorText = check.message;

        this.setState({
          validations: {
            ...this.state.validations,
            axt_userprincipalname: {
              ...this.state.validations.axt_userprincipalname,
              hasError: true,
              errorMensage: errorText
            }
          }
        }, () => { this.validateInput(el) });
      }
    }
  }

  backPage = () => {
    this.setState({ loadingForm: true });
    this.setState({ timelinePosition: this.state.timelinePosition - 1 });
    this.setState({ loadingForm: false });
  }

  nextPage = () => {
  }

  handleChangePassword = () => {
    this.setState({ modalOpen: "alterar-senha" });
  }

  handleSubmit = async e => {
    if (this.state.modalOpen == "alterar-senha") {
      return;
    }
    this.setState({ showFormErrors: true });
    let isValid = await this.formValido();
    if (isValid) {
      this.onSubmit(e);
    }
  };

  formValido = async () => {
    var valid = true;

    if (!this.state.form.listaPerfisAtribuidos.length) {
      this.setState({ isSubmit: false, erroPerfisDeAcesso: true });
      valid &= false;
    }

    for (var name in this.state.form) {
      let current = await this.validateInput(name);
      valid = valid && current;
    }

    return valid;
  };

  onSubmit = () => {
    const { timelinePosition, isSubmit } = this.state;

    if (isSubmit) {
      return;
    }

    if (this.state.form.LicensePlan === '') {
      this.setState({ isSubmit: false });
      return NotificationManager.error(
        "Você deve selecionar um plano válido.",
        "Não foi possivel editar o usuário!",
        4000
      );
    }

    const currentTimeLinePosition = timelinePosition;
    this.setState({ isSubmit: currentTimeLinePosition >= 1 ? true : false });

    if (currentTimeLinePosition < 1) {
      this.setState({ timelinePosition: this.state.timelinePosition + 1 });
      return;
    }

    this.setState({ loadingForm: true });

    if (!this.state.creating) {
      this.setState({ creating: true, loadingForm: true, showLoadingShadow: true });

      const signature = JSON.parse(sessionStorage.getItem('signature'));

      function GetOnlyFieldsThatChanges(initialState, finalState) {
        const fieldsThatChanges = {};

        Object.entries(initialState).forEach(initialValues => {
          if (finalState[initialValues[0]] !== initialValues[1])
            fieldsThatChanges[initialValues[0]] = finalState[initialValues[0]];
        });

        return fieldsThatChanges;
      }

      const fieldsChanged = GetOnlyFieldsThatChanges(this.state.initialUserInfo, this.state.form);
      fieldsChanged._axt_tenant_value = signature.axtTenantId;
      fieldsChanged._parentcustomerid_value = signature.accountId;
      fieldsChanged.userid = this.state.userId;
      fieldsChanged.axt_entitlementcontactroles = this.state.form.listaPerfisAtribuidos.
        map(perfil => { return { DireitoId: perfil?.DireitoId, PerfilDeAcesso: perfil?.PerfilDeAcessoCodigo } });

      if (!!fieldsChanged.axt_extensionattribute8)
        fieldsChanged.emailaddress2 = fieldsChanged.axt_extensionattribute8;

      const userData = {
        UsuarioId: fieldsChanged.userid,
        TenantId: signature.axtTenantId,
        LoginUsuario: fieldsChanged.axt_userprincipalname,
        DireitoId: signature.entitlementId,
        Cidade: null,
        Logradouro: null,
        Numero: null,
        Complemento: null,
        CEP: null,
        Estado: null,
        NomeParaExibicao: fieldsChanged.axt_displayname365,
        PerfisDeAcessoAosDireitos: fieldsChanged.axt_entitlementcontactroles,
        AtributoEstendido1: fieldsChanged.axt_extensionattribute1,
        AtributoEstendido2: fieldsChanged.axt_extensionattribute2,
        AtributoEstendido3: fieldsChanged.axt_extensionattribute3,
        AtributoEstendido4: fieldsChanged.axt_extensionattribute4,
        AtributoEstendido5: fieldsChanged.axt_extensionattribute5,
        AtributoEstendido6: fieldsChanged.axt_extensionattribute6,
        AtributoEstendido7: fieldsChanged.axt_extensionattribute7,
        AtributoEstendido8: fieldsChanged.axt_extensionattribute8,
        AtributoEstendido9: fieldsChanged.axt_extensionattribute9,
        AtributoEstendido10: fieldsChanged.axt_extensionattribute10,
        AtributoEstendido11: fieldsChanged.axt_extensionattribute11,
        AtributoEstendido12: fieldsChanged.axt_extensionattribute12,
        AtributoEstendido13: fieldsChanged.axt_extensionattribute13,
        AtributoEstendido14: fieldsChanged.axt_extensionattribute14,
        AtributoEstendido15: fieldsChanged.axt_extensionattribute15,
        Departamento: fieldsChanged.department,
        EmailPrincipal: fieldsChanged.emailaddress1,
        EmailAlternativo: fieldsChanged.emailaddress2,
        MatriculaDoFuncionario: null,
        Nome: fieldsChanged.firstname,
        Sobrenome: fieldsChanged.lastname,
        Cargo: fieldsChanged.jobtitle,
        TelefoneCelular: fieldsChanged.mobilephone,
        Apelido: null,
        TelefoneComercial: fieldsChanged.telephone1
      }

      UsuariosService.EditarUsuario(userData)
        .then((response) => {
          this.setState({ editarErro: false });
          this.setState({ editarResponse: [response] });
          this.setState({ timelinePosition: this.state.timelinePosition + 1 });
        })
        .catch((error) => {
          this.setState({ editarErro: true });
          this.setState({ editarResponse: error.response?.data?.erros ?? ['Erro ao tentar criar usuário.'] });
        })
        .finally(() => {
          this.setState({ creating: false, loadingForm: false });
          this.setState({ isSubmit: false });
          this.setState({ showLoadingShadow: false });
          this.setState({ modalOpen: "ResultadoValidacaoEditar" });
        });
    }
  };

  renderLoadingShadow() {
    return (
      <LoadingShadow
        sizeClassName={''}
        open={true}
        close={() => { }}
      />
    );
  }

  render() {
    const {
      formHeaders,
      loadingForm,
      loadingUserInfo,
      loadingAutorizacao,
      autorizado,
      timelinePosition,
      checkPermissionButtons,
      accepted,
      showLoadingShadow
    } = this.state;

    if (loadingForm || loadingUserInfo || loadingAutorizacao) {
      return (
        <MasterPage>
          <div className="small-loading">
            {showLoadingShadow && (this.renderLoadingShadow())}
          </div>
        </MasterPage>
      );
    }
    else if (!autorizado) {
      return <ErroGestaoUsuario />;
    }
    else if (this.props.listaFormFields.length === 0) {
      return (
        <MasterPage>
          <h1>Editar usuário</h1>
          <h2>Não há nenhum formulário no cadastro do tenant</h2>
          <h3>Não foi possível encontrar um formulário no cadastro do tenant, portanto, não é possível realizar o cadastro de novos usuários. Entre em contato com nossa equipe de atendimento.</h3>
        </MasterPage>
      )
    } else {
      return (
        <MasterPage>
          <FormHeader formHeaders={formHeaders} />
          <Timeline
            timelinePosition={timelinePosition}
            accepted={accepted}
            timelineLabelItems={['Dados do usuário', 'Confirmação e finalização']}
          />
          <ContentWrapperStyle>
            <form onSubmit={e => { e.preventDefault(); this.handleSubmit(e); }}>
              {timelinePosition === 0 && (
                //Dados do usuário
                <div>

                  <h2>Informações da empresa</h2>
                  <h3>Dados da empresa em que o usuário será cadastrado</h3>
                  <ContentBox className="form-container">
                    <div className="row">
                      {this.renderField(this.state.formContent.adx_organizationname, "adx_organizationname", "col-12 col-lg-12")}
                      {this.renderField(this.state.formContent.axt_extensionattribute3, "axt_extensionattribute3", "col-12 col-lg-6")}
                      {this.renderField(this.state.formContent.axt_extensionattribute4, "axt_extensionattribute4", "col-12 col-lg-6")}
                    </div>
                  </ContentBox>

                  <h2>Dados do usuário</h2>
                  <h3>Informe os dados do novo usuário</h3>
                  <ContentBox className="form-container">
                    <div className="row">
                      {this.renderField(this.state.formContent.firstname, "firstname", "col-12 col-lg-6")}
                      {this.renderField(this.state.formContent.lastname, "lastname", "col-12 col-lg-6")}
                      {this.renderField(this.state.formContent.axt_displayname365, "axt_displayname365", "col-12 col-lg-6")}
                      {this.renderField(this.state.formContent.mobilephone, "mobilephone", "col-12 col-lg-6")}
                      {this.renderField(this.state.formContent.telephone1, "telephone1", "col-12 col-lg-6")}
                      {this.renderField(this.state.formContent.axt_extensionattribute8, "axt_extensionattribute8", "col-12 col-lg-6", { onBlur: this.onBlurAlternateEmail, loading: this.state.searchAlternateEmail })}
                      {this.renderField(this.state.formContent.emailaddress2, "emailaddress2", "col-12 col-lg-6", { onBlur: this.onBlurAlternateEmail, loading: this.state.searchAlternateEmail })}
                      {this.renderField(this.state.formContent.department, "department", "col-12 col-lg-6")}
                      {this.renderField(this.state.formContent.jobtitle, "jobtitle", "col-12 col-lg-6")}
                      {this.renderFieldCheckBoxAcessor()}
                      {this.renderField(this.state.formContent.axt_extensionattribute5, "axt_extensionattribute5", "col-12 col-lg-6")}

                      {this.renderField(this.state.formContent.axt_extensionattribute1, "axt_extensionattribute1", "col-12 col-lg-6")}
                      {this.renderField(this.state.formContent.axt_userprincipalname, "axt_userprincipalname", "col-12 col-lg-6", { onBlur: this.onBlurUpn, loading: this.state.searchUpn })}
                      {this.renderField(this.state.formContent.emailaddress1, "emailaddress1", "col-12 col-lg-6", { loading: this.state.searchEmail })}
                    </div>
                  </ContentBox>
                  {this.renderPerfisDeAcesso()}
                </div>
              )}

              {timelinePosition === 1 && (
                <Confirmation
                  state={this.state}
                  props={this.props}
                />
              )}

              {timelinePosition > 1 && (
                <Concluded
                  renderNewUserPage={this.renderNewUserPage}
                />
              )}

              {timelinePosition <= 1 && (
                <NavigationButtons
                  labelBackButton='Voltar'
                  onClickBackButton={this.backPage}
                  hideBackButton={timelinePosition == 0}
                  labelNextButton={timelinePosition < 1 ? 'Próximo' : 'Confirmar'}
                  onClickNextButton={this.nextPage}
                  disabledNextButton={
                    checkPermissionButtons ?
                      (!this.formularioEhValido() ||
                        this.state.searchUpn ||
                        this.state.searchAlternateEmail ||
                        (!this.state.controlAdvisorCod && this.state.form.UsuarioAcessor) ||
                        (Number(this.state.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.ATIVO && Number(this.state.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.NAO_ENCONTRADO)) :
                      ((Number(this.state.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.ATIVO && Number(this.state.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.NAO_ENCONTRADO))
                  }
                  onClickChangePasswordButton={this.handleChangePassword}
                  hideChangePasswordButton={timelinePosition > 0 || !checkPermissionButtons}
                  disabledChangePasswordButton={(Number(this.state.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.ATIVO && Number(this.state.statuscode) !== CONSTANTES_AMBIENTE.TIPOS_STATUS_USUARIOS.NAO_ENCONTRADO)}
                />
              )}
              {this.renderModal()}
            </form>
          </ContentWrapperStyle>
        </MasterPage>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  userInfo: state.users.userInfo,
  listaFormFields: state.users.listaFormFields,
  listaPerfis: state.users.listaPerfis,
  listaPerfisLoading: state.users.listaPerfisLoading,
  listaLinhaServico: state.users.listaLinhaServico,
  listaLinhaServicoLoading: state.users.listaLinhaServicoLoading,
  listaProdutoComplemento: state.users.listaProdutoComplemento,
  listaProdutoComplementoLoading: state.users.listaProdutoComplementoLoading,
  listaDePacotesCSP: state.users.listaDePacotesCSP,
  listaDePacotesCSPLoading: state.users.listaDePacotesCSPLoading,
  listaPerfisAtribuidos: state.users.listaPerfisAtribuidos,
  listaPerfisAtribuidosLoading: state.users.listaPerfisAtribuidosLoading,
  user: state.users.user,
  account: state.users.account
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setLoadingItem,
      removeLoadingItem,
      rdxClearUsers,
      rdxGetUserFields,
      rdxGetPerfis,
      rdxGetLinhaServico,
      rdxGetUser,
      rdxGetPerfisUsuarioSelecionado,
      rdxIncluirPerfilAssociado,
      rdxRemoverPerfilAssociado,
      rdxGetPlansRightsByEntitlementId,
      rdxGetAdditionalProductsRightByEntitlementId,
      rdxGetAccountById
    },
    dispatch
  );

const component = connect(mapStateToProps, mapDispatchToProps)(EditarUsuariosV2);

export default withRouter(component);
