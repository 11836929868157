import { ConfirmFunctionStatus } from "context/DrawerContext";
import React from "react"
import { useEffect, useState } from "react"
import { StyledForm } from "components/Formulario/styles";
import UsuariosService from "services/UsuariosService";
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';
import LoadingOrErrorPanel from "components/LoadingOrErrorPanel";
import Formulario from "components/Formulario/Formulario";
import { Select, Modal } from 'antd';
import EmailsService from "services/EmailsService";
import { NotificationManager } from "react-notifications";
import { MessagesDefaultTimeout } from "constants/messagesDefaultTimeout";
import GruposService from "services/GruposService";
import ObjectUtils from "utils/objects";
import ArrayUtils from "utils/arrays";
import DominiosService from "services/DominiosService";
import { GRUPO_SETTINGS } from "../GrupoSettings";

const GrupoEdicao = forwardRef((props, ref) => {
    const [formRef] = StyledForm.useForm();
    const [inputsFormulario, setInputsFormulario] = useState([])
    const [loading, setLoading] = useState(true);
    const [erro, setErro] = useState({temErro: false, mensagem: ''});
    const [formularioErros, setFormularioErros] = useState([]);
    const [emailValido, setEmailValido] = useState(true);
    const [emailAtual, setEmailAtual] = useState('');
    const [valoresIniciais, setValoresIniciais] = useState({});
    const [formularioDesativado, setFormularioDesativado] = useState(false);
    const {setStatusBotaoConfirmar, closeDrawer } = props;

	useEffect(()=>{
        CriarFormulario();
    }, [])

    const confirmFunction = async () => {
        setFormularioErros([]);
        alterarStatusValidacaoEmail('', '', true);
        setFormularioDesativado(true);
        setStatusBotaoConfirmar(ConfirmFunctionStatus.Carregando);
        formRef.submit();
    }

    const cancelFunction = () => {}

    useImperativeHandle(ref, () => ({
        confirmFunction,
        cancelFunction
    }));

    const CriarFormulario = async () => {
        const grupo = await ObterGrupo();
        if (!grupo) {
            setLoading(false);    
            return
        }
        if (grupo.razaoStatus && grupo.razaoStatus == GRUPO_SETTINGS.RAZAO_STATUS.EM_SINCRONIZACAO) {
            closeDrawer();
            modalErro('O grupo já se encontra em sincronização. Aguarde a conclusão da operação para fazer a solicitação.');
            return;
        }

        const sufixos = await DominiosService.ListarSufixosPorCliente();
        if(!sufixos) {
            setErro({temErro: true, mensagem: 'Não foi possível obter a lista de sufixos'});
            setLoading(false);
            return
        }

        setValoresIniciais({
            ['nome']: grupo.nomeExibicao,
            ['email']: grupo.email?.split('@')[0],
            ['emailSufixo']: grupo.email?.split('@')[1],
            ['proprietarios']: grupo?.proprietarios?.map(x => x.usuarioId),
            ['membros']: grupo?.membros?.map(x => x.usuarioId),
            ['adicionarEquipeDoTeams']: grupo.equipeDoTeamsExiste,
            ['permitirRemetentesExternos']: grupo.permitirRemetentesExternos,
            ['notificarMembrosPorEmail']: grupo.notificarMembrosPorEmail
        });

        GerarInputsFormulario(sufixos, grupo?.proprietarios, grupo?.membros, grupo.equipeDoTeamsExiste);

        setLoading(false);
    }

    const ObterGrupo = async () => {
        let grupo;
        if (!props.grupoId) {
            setErro({temErro: true, mensagem: 'O Id do grupo não foi informado'});
        }

        await GruposService.ObterGrupoPorId(props.grupoId)
            .then(response => {
                setEmailAtual(response.email);
                grupo = response;
            })
            .catch(error => {
                closeDrawer();
                modalErro(error.response?.data?.erros[0] ?? 'Erro inesperado ao obter o grupo');
            })

        return grupo;
    }

	const GerarInputsFormulario = (sufixos, proprietarios, membros, equipeDoTeamsExiste) => {
        const selectSufixoOptions = sufixos.map(
            sufixo => <Select.Option key={sufixo} value={sufixo}>@{sufixo}</Select.Option>
        );

        const proprietariosDataSource = proprietarios.map(obj => {
            const { usuarioId, ...resto } = obj;
            return { key: usuarioId, ...resto };
        });

        const membrosDataSource = membros.map(obj => {
            const { usuarioId, ...resto } = obj;
            return { key: usuarioId, ...resto };
        });

        const colunasTabelasUsuario = [
            {
              title: 'Nome',
              dataIndex: 'nomeParaExibicao',
              key: 'nomeParaExibicao',
              width: '200px'
            },
            {
              title: 'Email',
              dataIndex: 'emailPrincipal',
              key: 'emailPrincipal',
              width: '200px'
            }
        ];
        
        let inputsFormulario = [];

        inputsFormulario.push(...[
            { 
                input:{ 
                    name: "nome", 
                    label: "Nome", 
                    type: "text",
                    rules: [{required: true, message:"Campo obrigatório"}],
                }
            },
            { 
                input:{ 
                    name: "email", 
                    label: "Endereço de email do grupo", 
                    type: "textSufixos",
                    rules: [{required: true, message:"Campo obrigatório"}],
                    hasFeedback: true
                },
                selectSufixoOptions
            },
            { 
                input:{ 
                    name: "proprietarios", 
                    label: "Proprietários", 
                    rules: [{required: true, message:"Campo obrigatório"}],
                    type: "table"
                },
                dataSource: proprietariosDataSource,
                columns: colunasTabelasUsuario,
                collapse: { open: true },
                adicionarDados: 
                {
                    tituloBotao: "Gerenciar Proprietários",
                    callback: async (filtro, tamanhoPagina, skipToken) => { return UsuariosService.ListarUsuariosGrupo(skipToken, filtro, tamanhoPagina) },
                    campoRegistrosDaListagem: 'usuarios',
                    campoIdDaListagem: 'usuarioId',
                    placeholderFiltro: 'Filtrar por nome ou e-mail'
                },
            },
            { 
                input:{ 
                    name: "membros", 
                    label: "Membros",
                    type: "table"
                },
                dataSource: membrosDataSource,
                columns: colunasTabelasUsuario,
                collapse: { open: false },
                adicionarDados: 
                {
                    tituloBotao: "Gerenciar Membros",
                    callback: async (filtro, tamanhoPagina, skipToken) => { return UsuariosService.ListarUsuariosGrupo(skipToken, filtro, tamanhoPagina) },
                    campoRegistrosDaListagem: 'usuarios',
                    campoIdDaListagem: 'usuarioId',
                    placeholderFiltro: 'Filtrar por nome ou e-mail'
                },
            },
            { 
                input:{ 
                    name: "adicionarEquipeDoTeams",
                    type: "checkbox", 
                    texto: "Adicionar uma equipe do Teams ao grupo",
                    disabled: equipeDoTeamsExiste
                }
            },
            { 
                input:{ 
                    name: "permitirRemetentesExternos", 
                    type: "checkbox",
                    texto: "Permitir que pessoas de fora da organização enviem e-mails para o grupo"
                }
            },
            { 
                input:{ 
                    name: "notificarMembrosPorEmail", 
                    type: "checkbox",
                    texto: "Enviar uma cópia dos e-mails e eventos para a caixa de entrada dos membros do grupo"
                }
            }
        ]);

        setInputsFormulario(inputsFormulario);
    }

    const validarFormulario = async () => {
        return formRef.validateFields({ validateOnly: true }).then(
            (values) => {
                if(Object.keys(values).length == 0){
                    return false;
                }
                return true;                
            }).catch((error)=>{
                return false;
            });
    }

    const alterarStatusValidacaoEmail = (statusValidacao, mensagem, desabilitarCampo) =>{
        let novosInputs = [...inputsFormulario];
        let emailIndex = novosInputs.findIndex(x=>x.input.name == "email")
        novosInputs[emailIndex] = {...novosInputs[emailIndex], input:{
            ...novosInputs[emailIndex].input,
            validateStatus: statusValidacao,
            help: mensagem,
            disabled: desabilitarCampo
        }}

        setInputsFormulario(novosInputs)
    }

    const validarEmail = async () => {
        let valoresFormulario = formRef.getFieldsValue(true);
        if (valoresFormulario.email) {
            let email = valoresFormulario.email + '@' + valoresFormulario.emailSufixo;
            if (email == emailAtual) {
                setEmailValido(true);
                return true;
            }
            alterarStatusValidacaoEmail('validating', 'Validando email', true);
    
            let retornoEmailValido = await EmailsService.ValidarEmailPrincipal(email)
                .then(() => {
                    alterarStatusValidacaoEmail('success', '', false);
                    return true;
                })
                .catch(error => {
                    alterarStatusValidacaoEmail('error', error.response?.data?.erros[0] ?? 'Erro ao validar email', false);
                    return false;
                })
            setEmailValido(retornoEmailValido);
            return retornoEmailValido;
        } else {
            alterarStatusValidacaoEmail('error', 'Campo obrigatório', false);
            setEmailValido(false);
            return false;
        }
    }

    const validarSeDadosForamAlterados = () => {
        let formValues = formRef.getFieldsValue(true);
        return !ObjectUtils.objetosSaoIguais(valoresIniciais, formValues);
    }

    const handleAlteracaoEmail = async (fieldName) => {
        if(fieldName == "email"){
            setEmailValido(false);
            setStatusBotaoConfirmar(ConfirmFunctionStatus.Carregando)

            const retornoEmailValido = await validarEmail();
            const formularioValido = await validarFormulario();

            if (retornoEmailValido && formularioValido && validarSeDadosForamAlterados()) {
                setStatusBotaoConfirmar(ConfirmFunctionStatus.Ok)
            } else {
                setStatusBotaoConfirmar(ConfirmFunctionStatus.Invalido)
            }
        }
    }

    const handleConfirmacao = async () => {
        const signature = JSON.parse(sessionStorage.getItem('signature'));
        let formValues = formRef.getFieldsValue(true);
        
        const valoresAlterados = ObjectUtils.obterApenasCamposAlterados(valoresIniciais, formValues);        
        if (valoresAlterados.email || valoresAlterados.emailSufixo) {
            valoresAlterados.email ??= formValues.email;
            valoresAlterados.emailSufixo ??= formValues.emailSufixo;
        }
        
        const grupo = {
            grupoId: props.grupoId,
            tenantId: signature.axtTenantId,
            direitoId: signature.entitlementId,
            nome: valoresAlterados.nome ?? null,
            emailPrefixo: valoresAlterados.email ?? null,
            emailSufixo: valoresAlterados.emailSufixo ?? null,
            proprietariosAdicionados: ArrayUtils.getAddedItems(valoresIniciais.proprietarios, formValues.proprietarios) ?? [],
            proprietariosRemovidos: ArrayUtils.getRemovedItems(valoresIniciais.proprietarios, formValues.proprietarios) ?? [],
            membrosAdicionados: ArrayUtils.getAddedItems(valoresIniciais.membros, formValues.membros) ?? [],
            membrosRemovidos: ArrayUtils.getRemovedItems(valoresIniciais.membros, formValues.membros) ?? [],
            adicionarEquipeDoTeams: valoresAlterados.adicionarEquipeDoTeams ?? null,
            permitirRemetentesExternos: valoresAlterados.permitirRemetentesExternos ?? null,
            notificarMembrosPorEmail: valoresAlterados.notificarMembrosPorEmail ?? null
        }

        await GruposService.EditarGrupo(grupo)
            .then(response => {
                closeDrawer();
                modalSucesso(response);
            })
            .catch(error => {
                setFormularioErros(error.response?.data?.erros ?? ['Erro ao criar grupo']);
                alterarStatusValidacaoEmail('', '', false);
                setFormularioDesativado(false);
                setStatusBotaoConfirmar(ConfirmFunctionStatus.Ok);
                modalErro(error.response?.data?.erros[0]);
            })
    }

    const handleFalha = () => {
        NotificationManager.error('Por favor preencha todos os dados corretamente.', 'Formulário incompleto!', MessagesDefaultTimeout.Error);
    };

    const handleAlteracaoNosValores = (isSubmitable) => {
        if (!validarSeDadosForamAlterados()){
            setStatusBotaoConfirmar(ConfirmFunctionStatus.Invalido)
            return;
        }
        if (isSubmitable && emailValido) {
            setStatusBotaoConfirmar(ConfirmFunctionStatus.Ok)
            return;
        }
        setStatusBotaoConfirmar(ConfirmFunctionStatus.Invalido)
    }
    
    const modalSucesso = (mensagem) => {
        Modal.success({
            title: 'Sucesso!',
            content: mensagem,
            centered: true
        });
    };

    const modalErro = (mensagem) => {
        Modal.error({
          title: 'Erro ao editar grupo',
          content: mensagem,
          centered: true
        });
    };

	return(
		<LoadingOrErrorPanel hasError={erro.temErro} loading={loading} mensagemErro={erro.mensagem}>
            <Formulario
                hideSubmitButton={true}
                inputsData={inputsFormulario}
                formReference={formRef}
                onFinish={handleConfirmacao}
                portalOnInputSufixosValueChange={handleAlteracaoEmail}
                onFinishFailed={handleFalha}
                onSubmitableChange={handleAlteracaoNosValores}  
                initialValues={valoresIniciais}
                formErrors={formularioErros}
                disabled={formularioDesativado}
            />
        </LoadingOrErrorPanel>
	)
})

export default GrupoEdicao;