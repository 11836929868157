import { Spin } from "antd";
import { SpinContainer } from "./Formulario/styles";
import PrecisoSuporteButton from "./PrecisoSuporte/PrecisoSuporteButton";

function LoadingOrErrorPanel({children, ...props}){
    return (
        <>
            {
                props.nomeMenu && props.hasError && (
                    <h1>{props.nomeMenu}</h1>
                )   
            }
            {
                props.hasError && (
                    <div key="has-error-key">
                        <p>Prezado cliente,</p>
                        { props.mensagemErro ?
                            Array.isArray(props.mensagemErro) ?
                                props.mensagemErro.map(mensagem => <p>{mensagem}</p>) :
                                <p>{props.mensagemErro}</p> :
                            <p>Não foi possível buscar os dados necessários.</p>
                        }
                        <p>Por favor, tente novamente ou nos contate através dos canais:</p>
                        <p><PrecisoSuporteButton texto="Preciso de suporte" mostrarIcone={false} tipo="link"/> | <a href = "mailto: atendimento@bhs.com.br">atendimento@bhs.com.br</a> | 31 4000-1744</p>
                    </div>
                )
            }
            {props.loading && 
                <SpinContainer key="spin-container-key">
                    <Spin/>
                </SpinContainer> 
            }
            {
                !props.hasError && !props.loading && (
                    <>
                        {children}
                    </>
                )
            }
        </>
    )

}
export default LoadingOrErrorPanel;