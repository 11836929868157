import React, { Component } from 'react';

import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import getTenantSignatureList from '../../../actions/usuarios/userSignature/getTenantSignatureList';
import putUserFavoriteSignature from '../../../actions/usuarios/userSignature/putUserFavoriteSignature';
import { setLoadingItem } from '../../loading/loadingActions'; 

import Dropdown from '../../../../assets/icons/arrow_down.svg';
import { getTenant, mapSessionStorageTenant } from 'EstruturaAntiga/actions/tenant/getTenant';
import DireitoService from 'services/DireitosService';

export default class Signature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      signatureList: [],
      currentSignature: {},
      showSignatureList: false,
    }
  }

  async componentDidMount() {
    const userSignature = JSON.parse(sessionStorage.getItem('signature'));

    this.setState({
      currentSignature: userSignature,
    });

    const signatureList = await getTenantSignatureList();

    const currentSignature = signatureList.filter(signature =>
      signature.tenantCode !== userSignature.tenantCode || signature.entitlementId !== userSignature.entitlementId);

    this.setState({
      signatureList: currentSignature,
      isLoading: false,
    });
  }

  atualizarFavorito = async (entitlementId, tenantCode) => {
    const loadingDynamicsName = 'Login Dynamics';

    this.setState({
      isLoading: true,
    });

    setLoadingItem(loadingDynamicsName);

    const data = {
      id: JSON.parse(sessionStorage.getItem('contact')).idContact,
      entitlementId,
      tenantCode
    };

    await putUserFavoriteSignature(data)
      .then(async () => {
        const showError = async (error) => {
          let status = (error.status || (error.response && error.response.status) || '503').toString();

          switch (status) {
            case '204':
              status = '401';
              break;
            case '404':
              status = '401';
              break;
            case '500':
              status = '503';
              break;
            default:
              break;
          }
        };
        await DireitoService.BuscarDireitoAtual()
          .then(async (signature) => {
            sessionStorage.setItem('signature', JSON.stringify(signature));

            await getTenant()
              .then(response => {
                if(response){
                  sessionStorage.setItem('tenant', JSON.stringify(mapSessionStorageTenant(response)));
                }
              });

            setLoadingItem();

            window.location.href = '/';  // alterar.
          })
          .catch((error) => { });
      });
  }

  saveSessionStorageSignature = (signature) => {
    sessionStorage.setItem('signature', JSON.stringify(signature));
  }

  onClickChangeSignatureShow = e => {
    const { showSignatureList } = this.state;

    if (e.screenX < 768) {
      this.setState({ showSignatureList: !showSignatureList })
    }
  }

  render() {
    const {
      signatureList,
      currentSignature,
      showSignatureList,
      isLoading,
    } = this.state;

    return (
      <div
        className="header-signature"
        onClick={e => this.onClickChangeSignatureShow(e)}
        onMouseEnter={() => this.setState({ showSignatureList: true })}
        onMouseLeave={() => this.setState({ showSignatureList: false })}
      >
        <strong>{currentSignature?.serviceLineName}</strong>
        <small>{currentSignature?.tenantName}</small>

        <img className="dropdown" src={Dropdown} alt="Dropdown" />

        {
          !!showSignatureList && (
            <div className="header-signature-list">
              <h5>Alterar assinatura</h5>

              {
                isLoading ? (
                  <div className="is_loading">
                    <svg className="spinner" width="32px" height="32px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle className="path" fill="none" strokeWidth="3" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
                    </svg>
                  </div>
                ) : (
                  signatureList.map(signature => (
                    <div
                      key={`${signature.serviceLineName}_${signature.tenantName}`}
                      onClick={async () => await this.atualizarFavorito(signature.entitlementId, signature.tenantCode)}
                      className="header-signature-item"
                    >
                      <span>{signature.serviceLineName}</span>
                      <small>{signature.tenantName}</small>
                    </div>
                  ))
                )
              }
            </div>
          )
        }
      </div>
    )
  }
}
