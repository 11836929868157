import PortalClienteDBService from "services/bases/PortalClienteDBService";


class DireitoService extends PortalClienteDBService{
    async ListarDireitosPorContato(){
        return await this.get("portaldb/Direitos")
    }
    async BuscarDireitoAtual(){
        return await this.get("portaldb/direitos/DireitoAtual");
    }
}

export default new DireitoService();