import React, { Component } from 'react';
import { userSignature } from "EstruturaAntiga/assets/httpRequest";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authContext } from '../../adalConfig';
import { logOut } from "../../actions/logOut";
import { Link } from 'react-router-dom';
import getPhoto from '../../actions/usuarios/getPhoto';
import { rdxOpenMasterModal } from '../../masterpages/redux/masterPagesActions';
import putUserFavoriteSignature from "../../actions/usuarios/userSignature/putUserFavoriteSignature";

import Dados from '../../../assets/icons/dados.svg';
import Senha from '../../../assets/icons/senha.svg';
import Sair from '../../../assets/icons/sair.svg';
import Dropdown from '../../../assets/icons/arrow_down.svg';

class UserNavigation extends Component {
	constructor(props) {
		super(props);

		this.state = {
			photo: '',
			isModalOpen: '',
			userSignature: {
				entitlementId: '',
				serviceLineId: '',
				accountId: '',
				tenantCode: '',
				entitlementName: '',
				tenantName: '',
				accountName: '',
				serviceLineName: ''
			},
			selectedSignature: {
				accountId: '',
				tenantCode: '',
				serviceLineId: ''
			},
			listSignatures: []
		}
	}

	componentWillMount = async () => {
		this.setState({ photo: getPhoto(), userSignature: userSignature() });
	}

	onCloseModal() {
		this.setState({ isModalOpen: false });
	}

	openModal() {
		let favoriteSignature = userSignature();

		this.setState({ selectedSignature: favoriteSignature.tenantName || favoriteSignature.serviceLineName }, () => this.setState({ isModalOpen: true }));
	}

	changeSignature = async () => {
		let favoriteSignature = userSignature();

		await putUserFavoriteSignature(favoriteSignature);
	}

	onChangeDDLSignature(event) {
		let currentSignature = event.target.value;

		this.setState({ selectedSignature: currentSignature });
	}

	renderEditarMeusDados() {
		let onPremisesSyncEnabled = JSON.parse(sessionStorage.getItem('contact'))?.onPremisesSyncEnabled;
		if (!onPremisesSyncEnabled) {
			return (
				<Link className="nav-item" to="/EditarMeusDados">
					<img src={Dados} alt="Atualizar meus dados" />
					<span>Atualizar meus dados</span>
				</Link>
			)
		}
	}

	renderNavItems() {
		return (
			<nav className="user-nav">
				{this.renderEditarMeusDados()}

				<Link className="nav-item" to="/AlterarSenha">
					<img src={Senha} alt="Alterar senha" />
					<span>Alterar senha</span>
				</Link>

				<span className="nav-item" onClick={() => logOut()}>
					<img src={Sair} alt="Logout" />
					<span>Sair</span>
				</span>
			</nav>
		)
	}

	openNav() {
		document.getElementById("idNavigation").style.display = "block";
	}

	closeNav() {
		document.getElementById("idNavigation").style.display = "none";
	}

	render() {

		return (
			<div>
				<div className="user-container">
					<div className="user-info-container">
						<img src={this.state.photo} className="user-photo" alt="Nome do usuário..." />
						<div>
							<span className="user-first-name">{authContext._user.profile.given_name}</span>
							<b className="user-signature-name"> {this.state?.userSignature?.tenantName || this.state?.userSignature?.serviceLineName}</b>
						</div>
						<img className="dropdown" src={Dropdown} alt="Dropdown"/>
					</div>

					<div className="user-nav-container">
						<h5>Configurações</h5>
						{this.renderNavItems()}
					</div>
				</div>
			</div>
		);
	};
}

export default withRouter(connect(
	state => ({

	}),
	(dispatch) => (bindActionCreators({
		rdxOpenMasterModal
	}, dispatch))
)(UserNavigation));
