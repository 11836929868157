import { useEffect, useState, useRef } from 'react';
import MasterPage from '../../../EstruturaAntiga/masterpages/authenticated';
import { Col, Flex, Pagination, Row, Space, Input } from 'antd';
import LoadingOrErrorPanel from 'components/LoadingOrErrorPanel';
import { NotFoundDiv } from './Styles';
import GruposService from 'services/GruposService';
import GrupoCard from './Components/GrupoCard';
import GrupoCriacaoButton from '../Cadastro/GrupoCriacaoButton';
import { useRoteiro } from 'context/RoteiroContext';

function ListagemGrupos(){
    const [erro, setErro] = useState({temErro: false, mensagem: ''});
    const [loading, setLoading] = useState(true);
    const [grupos, setGrupos] = useState([]);
    const [paginasArmazenadas, setPaginasArmazenadas] = useState({});
    const [filtroAplicado, setFiltroAplicado] = useState('');
    const {setReferenciaObjetosVisuais} = useRoteiro();
    const refBotaoCriarGrupo = useRef(null);
    const refFiltro = useRef(null);
    const refPaginacao = useRef(null);
    const { Search } = Input;
    const [paginacaoOpcoes, setPaginacaoOpcoes] = useState({
        total: 10,
        tamanhoPagina: 10,
        filtroDelayEmMs: 1000,
        paginaAtual: 1,
        skipToken: ''
    });

    const refs = [
        { id: 'refBotaoCriarGrupo', ref: refBotaoCriarGrupo },
        { id: 'refFiltroGrupos', ref: refFiltro },
        { id: 'refPaginacaoGrupos', ref: refPaginacao },
    ];

    useEffect(()=>{
        ListarGrupos();
        setReferenciaObjetosVisuais(refs);
    },[]);

    const ListarGrupos = async (pagina = 1, tamanhoPagina = 10, filtro = '', skipToken = '') =>{
        setLoading(true);
        const dados = await GruposService.ListarGrupos(filtro, tamanhoPagina, skipToken)
            .catch(error =>{
                setErro(true, error.response?.data?.erros[0] ?? 'Erro inesperado ao listar grupos');
                setLoading(false);
                return;
            });
        if(dados == undefined) return;

        setPaginacaoOpcoes((prevPaginationDataConfig) =>({
            ...prevPaginationDataConfig,
            total: pagina == 1 ? dados.quantidadeRegistros : prevPaginationDataConfig.total,
            skipToken: dados.skipToken == null ? '' : dados.skipToken
        }))
        setGrupos([...dados.grupos]);
        setPaginasArmazenadas((prevCachedPages) => ({
            ...prevCachedPages,
            [pagina]: [...dados.grupos],
        }));

        setLoading(false);
        return dados
    }
    
    const handleFiltrar = async (valor) => {
        setPaginasArmazenadas({});
        setFiltroAplicado(valor);
        setPaginacaoOpcoes({
            ...paginacaoOpcoes,
            paginaAtual: 1
        });

        await ListarGrupos(1, paginacaoOpcoes.tamanhoPagina, valor);
    }

    const handleAlteracaoDePaginacao = (paginaAtual, tamanhoPagina) =>{
        let tamanhoPaginaAtual = paginacaoOpcoes.tamanhoPagina;
        let skipToken = paginacaoOpcoes.skipToken;

        if (tamanhoPaginaAtual != tamanhoPagina) {
            skipToken = '';
            paginaAtual = 1;
            setPaginasArmazenadas({});
        }

        setPaginacaoOpcoes({
            ...paginacaoOpcoes,
            paginaAtual,
            tamanhoPagina,
            skipToken
        })

        if(paginasArmazenadas[paginaAtual] && tamanhoPaginaAtual == tamanhoPagina){
            setGrupos(paginasArmazenadas[paginaAtual]);
        } else {
            ListarGrupos(paginaAtual, tamanhoPagina, filtroAplicado, skipToken);
        }
    }

    return(
        <MasterPage>
            <div className="title-with-action">
                <h1>Grupos</h1>
                <GrupoCriacaoButton tipo="button" ref={refBotaoCriarGrupo} />
            </div>
            <Space direction='vertical' style={{ width: '100%' }}  >
                <Flex justify='flex-end' >
                    <div ref={refFiltro} >
                        <Search
                            placeholder="Filtrar por nome ou e-mail"
                            onSearch={handleFiltrar}
                            allowClear
                            style={{
                                width: 235
                            }}
                        />
                    </div>
                </Flex>
                <Flex vertical={true}>
                    <LoadingOrErrorPanel loading={loading} hasError={erro.temErro} mensagemErro={erro.mensagem}>
                        <Row gutter={[16, 16]}>
                            {
                                grupos.length > 0 && grupos.map(element =>{
                                    return(
                                        <Col key={element.grupoId}>
                                            <GrupoCard grupo={element} />
                                        </Col>
                                        )
                                    }
                                )
                            }
                            {
                                grupos.length == 0 && !loading && !erro.temErro &&(
                                    <NotFoundDiv justify='center' align='center'>
                                        <span>Nenhum grupo encontrado.</span>
                                    </NotFoundDiv>
                                )
                            }
                        </Row>
                    </LoadingOrErrorPanel>
                    <Flex justify='center' style={{paddingTop: '10px'}}>
                        <div ref={refPaginacao}>
                            <Pagination 
                                defaultCurrent={1}
                                simple={{ readOnly: true }}
                                total={paginacaoOpcoes.total}
                                showTotal={(total) => `Total ${total} grupos`}
                                pageSize={paginacaoOpcoes.tamanhoPagina}
                                onChange={handleAlteracaoDePaginacao}
                                disabled={loading}
                                showSizeChanger={paginacaoOpcoes.total > 10}
                                current={paginacaoOpcoes.paginaAtual}
                            />
                        </div>
                    </Flex>
                </Flex>
            </Space>
        </MasterPage>
    )
}

export default ListagemGrupos;