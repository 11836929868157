import { useState, useEffect } from "react";

export function usePagination ( fetchData, campoRegistrosDaListagem, buscarDadosAutomaticamente = true ) {
    const [dados, setDados] = useState([]);
    const [paginacaoLoading, setPaginacaoLoading] = useState(true);
    const [paginasArmazenadas, setPaginasArmazenadas] = useState({});
    const [filtroAplicado, setFiltroAplicado] = useState('');
    const [paginacaoErro, setPaginacaoErro] = useState({temErro: false, mensagens: [], statuscode: 0});
    const [paginacaoOpcoes, setPaginacaoOpcoes] = useState({
        total: 0,
        tamanhoPagina: 10,
        paginaAtual: 1,
        skipToken: '',
        filtro: ''
    });

    useEffect(()=>{
        if (buscarDadosAutomaticamente) {
            executarFetchData();
        }
    }, []);

    const executarFetchData = async (paginaAtual = 1, tamanhoPagina = paginacaoOpcoes.tamanhoPagina, filtro = '', skipToken = '') => {
        try {
            setPaginacaoLoading(true);
            const response = await fetchData(tamanhoPagina, filtro, skipToken);
            if(!response) return;

            setPaginacaoOpcoes((paginacaoOpcoes) => ({
                ...paginacaoOpcoes,
                total: paginaAtual == 1 ? response.quantidadeRegistros : paginacaoOpcoes.total,
                skipToken: response.skipToken == null ? '' : response.skipToken
            }));

            setDados(response[campoRegistrosDaListagem]);
            setPaginasArmazenadas((prevPaginasArmazenadas) => ({
                ...prevPaginasArmazenadas,
                [paginaAtual]: response[campoRegistrosDaListagem] 
            }));
        }
        catch (error) {
            setPaginacaoErro({temErro: true, statuscode: error.response?.status, mensagens: error.response?.data?.erros ?? ['Erro inesperado ao obter os registros.']});
        }
        finally {
            setPaginacaoLoading(false);
        }
    }

    const handleAlteracaoDePaginacao = async (paginaAtual = paginacaoOpcoes.paginaAtual, tamanhoPagina = paginacaoOpcoes.tamanhoPagina) =>{
        let tamanhoPaginaAtual = paginacaoOpcoes.tamanhoPagina;
        let skipToken = paginacaoOpcoes.skipToken;
    
        if (tamanhoPaginaAtual != tamanhoPagina) {
            skipToken = '';
            paginaAtual = 1;
            setPaginasArmazenadas({});
        }
    
        setPaginacaoOpcoes({
            ...paginacaoOpcoes,
            paginaAtual,
            tamanhoPagina,
            skipToken
        })
    
        if(paginasArmazenadas[paginaAtual] && tamanhoPaginaAtual == tamanhoPagina){
            setDados(paginasArmazenadas[paginaAtual]);
        } else {
            await executarFetchData(paginaAtual, tamanhoPagina, '', skipToken);
        }
    }

    const handleFiltro = async (valorFiltro) => {
        setPaginasArmazenadas({});
        setFiltroAplicado(valorFiltro);
        setPaginacaoOpcoes({
            ...paginacaoOpcoes,
            paginaAtual: 1
        });
    
        await executarFetchData(1, paginacaoOpcoes.tamanhoPagina, valorFiltro);
    }

    const resetarPaginacao = () => {
        setPaginasArmazenadas({});
        setFiltroAplicado('');
        setPaginacaoOpcoes({
            ...paginacaoOpcoes,
            total: 0,
            paginaAtual: 1,
            skipToken: '',
            filtro: ''
        });   
    }

    return {
        dados,
        paginacaoOpcoes,
        paginacaoLoading,
        paginacaoErro,
        filtroAplicado,
        handleAlteracaoDePaginacao,
        executarFetchData,
        setDados,
        setPaginasArmazenadas,
        handleFiltro,
        setPaginacaoLoading,
        resetarPaginacao
    };
};