import CONSTANTES_AMBIENTE from "constants/constantesAmbiente";

export default function TemAcessoAosProfiles(profiles, withoutBHS) {
	const user = JSON.parse(sessionStorage.getItem('contact'));
	const signature = JSON.parse(sessionStorage.getItem('signature'));
	const tenantCode = signature?.tenantCode ? signature?.tenantCode?.toLowerCase() : '';

  let temAcesso = false;

  if (withoutBHS && tenantCode === CONSTANTES_AMBIENTE.CONFIGURACOES.TENANT_CODE_BHS) {
    temAcesso = false;
		return temAcesso;
  }

  const perfisAcessoUsuario = user?.entries ?? []; 

	profiles.forEach( profile => {
    if(temAcesso){
      return;
    }
    temAcesso = TemAcessoAoProfile(profile, perfisAcessoUsuario);
  });

	return temAcesso;
}

const TemAcessoAoProfile = (profile, perfisAcessoUsuario) => {

	let direito = profile.idEntitlement;

	if (profile.TerAcessoAoDireitoAssinaturaSelecionada){
    let assinaturaSelecionada = JSON.parse(sessionStorage.getItem('signature'));
    if (!assinaturaSelecionada?.entitlementId){
      return false;
    }
    direito = assinaturaSelecionada.entitlementId;
  } 

	const perfilAcessoUsuario = perfisAcessoUsuario.filter(perfil => {
    return (perfil.idAccount == profile.idAccount || !profile.idAccount)
	   && (perfil.idAxtRole == profile.idAxtRole || !profile.idAxtRole)
	   && (perfil.idEntitlement == direito || !direito)
	   && (perfil.idServiceLine == profile.idServiceLine || !profile.idServiceLine)
	   && (perfil.serviceLineTypeCode == profile.serviceLineTypeCode || !profile.serviceLineTypeCode)
  }); 

  if(perfilAcessoUsuario.length){
    return true;
  }

	return false;
}
