import ArrayUtils from "./arrays";

const ObjectUtils = {
  /**
   * Recebe 2 objetos como parametro e compara se os valores e chaves são iguais. Aceita objetos com valores primitivos, object e function
   * @param {*} objeto1 
   * @param {*} objeto2 
   * @returns 
   */
  objetosSaoIguais(objeto1, objeto2) {
    if (this.objetoInvalido(objeto1) || this.objetoInvalido(objeto2)) {
      return false;
    }
    // Verifica se o número de chaves é o mesmo
    const keys1 = Object.keys(objeto1);
    const keys2 = Object.keys(objeto2);
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Verifica se as chaves e os valores são iguais
    for (let key of keys1) {
      const value1 = objeto1[key];
      const value2 = objeto2[key];

      if (Array.isArray(value1) && Array.isArray(value2)) {
        // Comparação de arrays
        if (!ArrayUtils.arraysAreEqual(value1, value2)) {
            return false;
        }
      } else if (typeof value1 === 'object' && typeof value2 === 'object') {
        // Se ambos os valores forem objetos, compara recursivamente
        if (!this.objetosSaoIguais(value1, value2)) {
          return false;
        }
      } else if (typeof value1 === 'function' && typeof value2 === 'function') {
        if (value1.toString() !== value2.toString()) {
          return false;
        }
      } else {
        // Caso contrário, compara os valores diretamente
        if (value1 !== value2) {
          return false;
        }
      }
    }

    return true;
  },
  objetoInvalido(objeto) {
    return objeto == null || objeto == undefined || objeto == NaN;
  },
  obterApenasCamposAlterados(objeto1, objeto2) {
    const resultado = {};

    for (let key of Object.keys(objeto2)) {
        const value1 = objeto1[key];
        const value2 = objeto2[key];

        if (Array.isArray(value1) && Array.isArray(value2)) {
          // Tratar diferenças entre arrays
          const diferencasArray = value2.filter((item, index) => {
              // Se item na posição `index` não for igual, mantém no array
              return !this.objetosSaoIguais(value1[index], item);
          });
          if (diferencasArray.length > 0) {
              resultado[key] = diferencasArray;
          }
        } else if (typeof value1 === 'object' && typeof value2 === 'object' && !this.objetoInvalido(value1) && !this.objetoInvalido(value2)) {
            // Se ambos forem objetos, faz comparação recursiva
            if (!this.objetosSaoIguais(value1, value2)) {
                const diferencas = this.obterApenasCamposAlterados(value1, value2);
                if (Object.keys(diferencas).length > 0) {
                    resultado[key] = diferencas;
                }
            }
        } else {
            // Usa a função `objetosSaoIguais` para comparar os valores diretamente
            if (!this.objetosSaoIguais({ key: value1 }, { key: value2 })) {
                resultado[key] = value2;
            }
        }
    }

    return resultado;
    },
    ObjetoEhVazio(objeto) {
      return (
        objeto &&
        Object.keys(objeto).length === 0 &&
        objeto.constructor === Object
      );
    }
  }

export default ObjectUtils;