import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';
import urlBase from 'EstruturaAntiga/assets/urlsBase';

export default async function getTenantSignatureList() {
    let token = HttpRequest.contact()?.tokenData;
	let tenants = JSON.parse(sessionStorage.getItem("tenants"));
    if (!tenants || (token && new Date(token.expiration) < new Date())){
		let url = `${urlBase.urlBase}/portaldb/Tenants`;
		let _header = await HttpRequest.apiTokenHeader();
		let params = { method: 'GET', headers: _header };
	
		return fetch(url, params)
			.then(response => response.json())
			.then(result => {
				sessionStorage.setItem("tenants", JSON.stringify(result));
				return result;
			});
	}else{
		return tenants;
	}

}
