import PortalClienteService from "services/bases/PortalClienteService";
import * as HttpRequest from 'EstruturaAntiga/assets/httpRequest';

class UsuariosService extends PortalClienteService{
    async ListarUsuarios(dataUrlAtributes) {
        const userSignature = HttpRequest.userSignature();

        const defaultUrlAtributes = {
            tenantCode: userSignature.tenantCode,
            codigoCliente: userSignature.accountNumber ? userSignature.accountNumber : '',
            filtro: '',
            tamanhoPagina: 10,
            skipToken: ''
        };

        const urlAtributes = Object.assign(defaultUrlAtributes, dataUrlAtributes);
        const stringParams = Object.keys(urlAtributes).map(el => el + '=' + urlAtributes[el]).join("&");

        return await this.get(`usuarios?${stringParams}`);
    }
    async NovoListarUsuarios(skipToken = '', filtro = '', tamanhoPagina = 10) {
        const userSignature = HttpRequest.userSignature();
        const tenantCode = userSignature.tenantCode;
        const codigoCliente = userSignature.accountNumber ? userSignature.accountNumber : '';

        return await this.get(`usuarios?tenantCode=${tenantCode}&codigoCliente=${codigoCliente}&skipToken=${skipToken}&filtro=${filtro}&tamanhoPagina=${tamanhoPagina}`);
    }
    async ListarUsuariosGrupo(skipToken = '', filtro = '', tamanhoPagina = 10) {
        const userSignature = HttpRequest.userSignature();
        const tenantCode = userSignature.tenantCode;
        const codigoCliente = userSignature.accountNumber ? userSignature.accountNumber : '';

        return await this.get(`usuarios/ListarUsuariosGrupo365?tenantCode=${tenantCode}&codigoCliente=${codigoCliente}&skipToken=${skipToken}&filtro=${filtro}&tamanhoPagina=${tamanhoPagina}`);
    }
    async ListarPerfisDeAcesso(usuarioId){
        return await this.get(`usuarios/ListarPerfisDeAcesso?usuarioId=${usuarioId}`)
    }
    async ValidarHabilitacaoGestaoUsuarios(){
        const direitoId = HttpRequest.userSignature().entitlementId;
        return await this.get(`usuarios/ValidarHabilitacaoGestaoUsuarios?direitoId=${direitoId}`)
    }
    async CriarUsuario(usuarioDTO) {
        return await this.post("Usuarios/", usuarioDTO);
    }
    async EditarUsuario(usuarioDTO) {
        return await this.put("Usuarios/", usuarioDTO);
    }
    async RedefinirMinhaSenha(redefinirSenhaDTO) {
        return await this.put("usuarios/RedefinirMinhaSenha", redefinirSenhaDTO, false);
    }
    async EditarLicenciamento(editarLicenciamentoDTO) {
        return await this.patch("Usuarios/EditarLicenciamento", editarLicenciamentoDTO);
    }
    async AtualizarMeusDados(meusDados) {
        return await this.patch("Usuarios/EditarMeusDados", meusDados);
    }
    async ExcluirUsuario(excluirUsuarioDto) {
        return await this.delete("Usuarios/", excluirUsuarioDto);
    }
}

export default new UsuariosService();