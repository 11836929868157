import React, { Component } from 'react';
import Rotas from './EstruturaAntiga/router/router';
import Loading from './EstruturaAntiga/components/loading/Loading';
import authenticationBHSToken from './EstruturaAntiga/actions/authenticationBHSToken.js';
import * as HttpRequest from "EstruturaAntiga/assets/httpRequest";
import getListarTermosParaAceite from "./EstruturaAntiga/actions/termos/getListarTermosParaAceite";
import getFoto from "./EstruturaAntiga/actions/account/getFoto";
import photoDefault from './assets/images/user-default.svg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getTermos } from './EstruturaAntiga/pages/termosDeAceite/termoAceiteActions';
import { setLoadingItem, removeLoadingItem } from './EstruturaAntiga/components/loading/loadingActions';
import { errorPage } from './EstruturaAntiga/router/redux/routerActions';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import './EstruturaAntiga/stylesheets/style.scss';
import './EstruturaAntiga/stylesheets/vendor/icomoon/fonts/icomoon.ttf';
import './EstruturaAntiga/stylesheets/vendor/icomoon/fonts/icomoon.woff';
import './EstruturaAntiga/stylesheets/vendor/icomoon/fonts/icomoon.svg';
import { getTenant, mapSessionStorageTenant } from 'EstruturaAntiga/actions/tenant/getTenant';
import ClearCache from './EstruturaAntiga/ClearCache';
import ErrorBoundary from 'EstruturaAntiga/errorBoundary';
import { getContact } from 'EstruturaAntiga/actions/contacts/getContact';
import getUsuarioAutenticadoAAD from 'EstruturaAntiga/actions/usuarios/getUsuarioAutenticadoAAD';
import DireitoService from 'services/DireitosService';
import { ConfigProvider } from 'antd';
import GlobalTheme from 'GlobalTheme';
import ptBr from 'antd/locale/pt_BR';
import { RoteiroProvider } from 'context/RoteiroContext';
import { MenuProvider } from 'context/MenuContext';

class App extends Component {
	state = {
		content: [],
		authorized: false
	}

	componentDidMount() {
		this.getDynamicsUser();
	}

	getDynamicsUser = async () => {
		if (sessionStorage.getItem('contact') && sessionStorage.getItem('signature') && sessionStorage.getItem('tenant')) {
			this.getPendingAceptTerms();
			this.setState({ content: this.content() });
		} else {
			let user = HttpRequest.adalUser();
			let loadingDynamicsName = 'Login Dynamics';

			this.props.setLoadingItem(loadingDynamicsName);

			const showError = async (error) => {
				let status = (error.status || (error.response && error.response.status) || '503').toString();

				switch (status) {
					case '204':
						status = '401';
						break;
					case '404':
						status = '401';
						break;
					case '500':
						status = '503';
						break;
					default:
						break;
				}

				await this.props.errorPage(status);
				this.props.removeLoadingItem(loadingDynamicsName);
				this.setState({ content: this.content() });
			};

			await authenticationBHSToken()
				.then(async (authData) => {
					if (!authData.erros) {
						sessionStorage.setItem('contact', JSON.stringify(authData));

						await getContact()
						.then(async response => {
							if(response){
								let contact = response;
								contact.tokenData = authData.tokenData;
								contact.message = authData.message;
								contact.authenticated = authData.authenticated;

								await getUsuarioAutenticadoAAD(user.profile.upn, user.profile.tid)
								.then(async (data) => {
									contact.onPremisesSyncEnabled = data.onPremisesSyncEnabled;
								})
								.catch(error => showError(error));

								await getFoto()
								.then((obj) => {
									contact.userPhoto = obj.error ? photoDefault : 'data:image/jpeg;base64,' + obj;
								})
								.catch(() => {
									contact.userPhoto = photoDefault;
								});

								sessionStorage.setItem('contact', JSON.stringify(contact));
							}
						});

						await DireitoService.BuscarDireitoAtual()
							.then(response => {
								if(response){
									sessionStorage.setItem('signature', JSON.stringify(response));
								}
							});

						await getTenant()
							.then(response => {
								if(response){
									sessionStorage.setItem('tenant', JSON.stringify(mapSessionStorageTenant(response)));
								}
							});

						this.setState({ content: this.content(), authorized: true });
						this.props.removeLoadingItem(loadingDynamicsName);
						this.getPendingAceptTerms();
					} else {
						await this.props.errorPage('401');
						this.props.removeLoadingItem(loadingDynamicsName);
						this.setState({ content: this.content(), authorized: false });
						window.sessionStorage.clear();
						window.localStorage.clear();
						authContext.clearCache();
					}
				})
				.catch((error) => showError(error));
		}
	}

	getPendingAceptTerms() {
		let loadingTermosName = 'Termos de aceite pendentes';

		this.props.setLoadingItem(loadingTermosName);

		getListarTermosParaAceite()
			.then((obj) => {
				this.props.removeLoadingItem(loadingTermosName);
				if (obj.length)
					this.props.getTermos(obj)
			})
			.catch((error) => {
				this.props.removeLoadingItem(loadingTermosName);
			});
	}

	content() {
		return (<Rotas />);
	}

	render() {
		return (
			<div className="app">
				<ErrorBoundary>
					<ConfigProvider theme={GlobalTheme} locale={ptBr}>
						<MenuProvider>
							<RoteiroProvider authorized={this.state.authorized}>
								<ClearCache/>
								<Loading />
								<NotificationContainer className="global-notification" />
								{this.state.content}
							</RoteiroProvider>
						</MenuProvider>
					</ConfigProvider>
				</ErrorBoundary>
			</div>
		);
	}
}

const mapDispatchToProps = dispatch => (bindActionCreators({ getTermos, setLoadingItem, removeLoadingItem, errorPage }, dispatch))
export default connect(null, mapDispatchToProps)(App);
