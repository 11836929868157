import React, { useEffect } from 'react';
import { adalGetToken } from 'react-adal';

import { authContext, adalConfig } from '../../adalConfig';

import LoginBg from '../../../assets/images/login-bg.png';
import Logo from '../../../assets/images/logo.png';

import './style.scss';

export function Home({ history }) {
  useEffect(() => {
    getAdalToken();
  }, []);

  async function getAdalToken() {
    await adalGetToken(authContext, adalConfig.endpoints.api)
    .then(() => history.push('/dashboard'))
    .catch((error) => {
      window.sessionStorage.clear();
      window.localStorage.clear();
      authContext.clearCache();
    });
  }

  return (
    <div className="login-page">
      <img src={LoginBg} alt="Login Background" />

      <div className="login-wrapper">
        <div>
          <img src={Logo} alt="BHS" />

          <div className="login-titles">
            <h2>Boas Vindas!</h2>
            <h3>Este é o seu Portal do Cliente</h3>
          </div>

          <div className="login-buttons">
            <button className="login-button" onClick={() => authContext.login()}>Entrar</button>
            <button className="forget-password-btn" onClick={() => history.push('/esqueci-minha-senha')}>Esqueci minha senha</button>
          </div>
        </div>
      </div>
    </div>
  );
}
