import { ConfirmFunctionStatus } from "context/DrawerContext";
import React from "react"
import { useEffect, useState } from "react"
import { StyledForm } from "components/Formulario/styles";
import UsuariosService from "services/UsuariosService";
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';
import LoadingOrErrorPanel from "components/LoadingOrErrorPanel";
import Formulario from "components/Formulario/Formulario";
import { adalUser } from "EstruturaAntiga/assets/httpRequest";
import { Select, Modal } from 'antd';
import EmailsService from "services/EmailsService";
import { NotificationManager } from "react-notifications";
import { MessagesDefaultTimeout } from "constants/messagesDefaultTimeout";
import GruposService from "services/GruposService";
import DominiosService from "services/DominiosService";

const GrupoCriacao = forwardRef((props, ref) => {
    const [formRef] = StyledForm.useForm();
    const [inputsFormulario, setInputsFormulario] = useState([])
    const [loading, setLoading] = useState(true);
    const [formularioErros, setFormularioErros] = useState([]);
    const [emailValido, setEmailValido] = useState(false);
    const [formularioDesativado, setFormularioDesativado] = useState(false);
    const [valoresIniciais, setValoresIniciais] = useState({});
    const {setStatusBotaoConfirmar, closeDrawer } = props;

	useEffect(()=>{
        CriarFormulario();
    }, [])

    const confirmFunction = async () => {
        setFormularioErros([]);
        alterarStatusValidacaoEmail('', '', true);
        setFormularioDesativado(true);
        setStatusBotaoConfirmar(ConfirmFunctionStatus.Carregando);
        formRef.submit();
    }
    
    const cancelFunction = () => {}

    useImperativeHandle(ref, () => ({
        confirmFunction,
        cancelFunction
    }));

    const CriarFormulario = async () => {
        const signature = JSON.parse(sessionStorage.getItem('signature'));

        const sufixos = await DominiosService.ListarSufixosPorCliente();
        if(sufixos?.length == 0) {
            modalErro('Não foi possível obter a lista de sufixos de e-mail, favor entrar em contato com o nosso atendimento.');
            setLoading(false);
            closeDrawer();
            return
        }

        const usuarioLogado = adalUser().profile;
        let proprietarioPadrao = null;
        if (signature.tenantCode == usuarioLogado.tid) {
            proprietarioPadrao = {
                key: usuarioLogado.oid,
                nomeParaExibicao: usuarioLogado.name,
                emailPrincipal: usuarioLogado.email
            }
        }

        setValoresIniciais({
            ['emailSufixo']: sufixos[0],
            ['proprietarios']: proprietarioPadrao ? [proprietarioPadrao.key] : [],
            ['permitirRemetentesExternos']: true,
            ['notificarMembrosPorEmail']: true
        });

        GerarInputsFormulario(sufixos, proprietarioPadrao);

        setLoading(false);
    }

	const GerarInputsFormulario = (sufixos, proprietarioPadrao = null) => {
        const selectSufixoOptions = sufixos.map(
            sufixo => <Select.Option key={sufixo} value={sufixo}>@{sufixo}</Select.Option>
        );

        const colunasTabelasUsuario = [
            {
              title: 'Nome',
              dataIndex: 'nomeParaExibicao',
              key: 'nomeParaExibicao',
              width: '200px'
            },
            {
              title: 'Email',
              dataIndex: 'emailPrincipal',
              key: 'emailPrincipal',
              width: '200px'
            }
        ];
        
        let proprietariosDataSource = [];
        if (proprietarioPadrao) {
            proprietariosDataSource.push(proprietarioPadrao);
        }
        
        let inputsFormulario = [];

        inputsFormulario.push(...[
            { 
                input:{ 
                    name: "nome", 
                    label: "Nome", 
                    type: "text",
                    rules: [{required: true, message:"Campo obrigatório"}],
                }
            },
            { 
                input:{ 
                    name: "email", 
                    label: "Endereço de email do grupo", 
                    type: "textSufixos",
                    rules: [{required: true, message:"Campo obrigatório"}],
                    hasFeedback: true
                },
                selectSufixoOptions
            },
            { 
                input:{ 
                    name: "proprietarios", 
                    label: "Proprietários", 
                    rules: [{required: true, message:"Campo obrigatório"}],
                    type: "table"
                },
                dataSource: proprietariosDataSource,
                columns: colunasTabelasUsuario,
                collapse: { open: true },
                adicionarDados: 
                {
                    tituloBotao: "Gerenciar Proprietários",
                    callback: async (filtro, tamanhoPagina, skipToken) => { return UsuariosService.ListarUsuariosGrupo(skipToken, filtro, tamanhoPagina) },
                    campoRegistrosDaListagem: 'usuarios',
                    campoIdDaListagem: 'usuarioId',
                    placeholderFiltro: 'Filtrar por nome ou e-mail'
                },
            },
            { 
                input:{ 
                    name: "membros", 
                    label: "Membros", 
                    type: "table",
                },
                dataSource: [],
                columns: colunasTabelasUsuario,
                collapse: { open: false },
                adicionarDados: 
                {
                    tituloBotao: "Gerenciar Membros",
                    callback: async (filtro, tamanhoPagina, skipToken) => { return UsuariosService.ListarUsuariosGrupo(skipToken, filtro, tamanhoPagina) },
                    campoRegistrosDaListagem: 'usuarios',
                    campoIdDaListagem: 'usuarioId',
                    placeholderFiltro: 'Filtrar por nome ou e-mail'
                },
            },
            { 
                input:{ 
                    name: "adicionarEquipeDoTeams",
                    type: "checkbox", 
                    texto: "Adicionar uma equipe do Teams ao grupo"
                }
            },
            { 
                input:{ 
                    name: "permitirRemetentesExternos", 
                    type: "checkbox",
                    texto: "Permitir que pessoas de fora da organização enviem e-mails para o grupo"
                }
            },
            { 
                input:{ 
                    name: "notificarMembrosPorEmail", 
                    type: "checkbox",
                    texto: "Enviar uma cópia dos e-mails e eventos para a caixa de entrada dos membros do grupo"
                }
            }
        ]);
        
        setInputsFormulario(inputsFormulario);
    }

    const validarFormulario = async () => {
        return formRef.validateFields({ validateOnly: true }).then(
            (values) => {
                if(Object.keys(values).length == 0){
                    return false;
                }
                return true;                
            }).catch((error)=>{
                return false;
            });
    }

    const alterarStatusValidacaoEmail = (statusValidacao, mensagem, desabilitarCampo) =>{
        let newInputs = [...inputsFormulario];
        let emailIndex = newInputs.findIndex(x=>x.input.name == "email")
        newInputs[emailIndex] = {...newInputs[emailIndex], input:{
            ...newInputs[emailIndex].input,
            validateStatus: statusValidacao,
            help: mensagem,
            disabled: desabilitarCampo
        }}

        setInputsFormulario(newInputs)
    }

    const validarEmail = async () => {
        let formValues = formRef.getFieldsValue(true);
        if (formValues.email) {
            alterarStatusValidacaoEmail('validating', 'Validando email', true);
            let email = formValues.email + '@' + formValues.emailSufixo;
    
            let retornoEmailValido = await EmailsService.ValidarEmailPrincipal(email)
                .then(() => {
                    alterarStatusValidacaoEmail('success', '', false);
                    return true;
                })
                .catch(error => {
                    alterarStatusValidacaoEmail('error', error.response?.data?.erros[0] ?? 'Erro ao validar email', false);
                    return false;
                })
            setEmailValido(retornoEmailValido);
            return retornoEmailValido;
        } else {
            alterarStatusValidacaoEmail('error', 'Campo obrigatório', false);
            setEmailValido(false);
            return false;
        }
    }

    const handleAlteracaoEmail = async (fieldName) => {
        if(fieldName == "email"){
            setEmailValido(false);
            setStatusBotaoConfirmar(ConfirmFunctionStatus.Carregando)

            const retornoEmailValido = await validarEmail();
            const formularioValido = await validarFormulario();

            if (retornoEmailValido && formularioValido) {
                setStatusBotaoConfirmar(ConfirmFunctionStatus.Ok)
            } else {
                setStatusBotaoConfirmar(ConfirmFunctionStatus.Invalido)
            }
        }
    }

    const handleConfirmacao = async () => {
        const signature = JSON.parse(sessionStorage.getItem('signature'));
        const formValues = formRef.getFieldsValue(true);

        const grupo = {
            tenantId: signature.axtTenantId,
            direitoId: signature.entitlementId,
            nome: formValues.nome,
            emailPrefixo: formValues.email,
            emailSufixo: formValues.emailSufixo,
            proprietariosAdicionados: formValues.proprietarios ?? [],
            membrosAdicionados: formValues.membros ?? [],
            adicionarEquipeDoTeams: formValues.adicionarEquipeDoTeams ?? false,
            permitirRemetentesExternos: formValues.permitirRemetentesExternos ?? false,
            notificarMembrosPorEmail: formValues.notificarMembrosPorEmail ?? false,
        }

        await GruposService.CriarGrupo(grupo)
            .then(response => {
                closeDrawer();
                modalSucesso(response);
            })
            .catch(error => {
                setFormularioErros(error.response?.data?.erros ?? ['Erro inesperado ao criar grupo']);
                alterarStatusValidacaoEmail('', '', false);
                setFormularioDesativado(false);
                setStatusBotaoConfirmar(ConfirmFunctionStatus.Ok);
                modalErro(error.response?.data?.erros[0] ?? ['Erro inesperado ao criar grupo']);
            })
    }

    const handleFalha = () => {
        NotificationManager.error('Por favor preencha todos os dados corretamente.', 'Formulário incompleto!', MessagesDefaultTimeout.Error);
    };

    const handleAlteracaoNosValores = (isSubmitable) => {
        if (isSubmitable && emailValido) {
            setStatusBotaoConfirmar(ConfirmFunctionStatus.Ok)
            return;
        }
        setStatusBotaoConfirmar(ConfirmFunctionStatus.Invalido)
    }

    const modalSucesso = (mensagem) => {
        Modal.success({
            title: 'Sucesso!',
            content: mensagem,
            centered: true
        });
    };
    
    const modalErro = (mensagem) => {
        Modal.error({
            title: 'Erro criar grupo',
            content: mensagem,
            centered: true
        });
    };

	return(
		<LoadingOrErrorPanel loading={loading}>
            <Formulario
                hideSubmitButton={true}
                inputsData={inputsFormulario}
                formReference={formRef}
                onFinish={handleConfirmacao}
                portalOnInputSufixosValueChange={handleAlteracaoEmail}
                onFinishFailed={handleFalha}
                onSubmitableChange={handleAlteracaoNosValores}  
                initialValues={valoresIniciais}
                formErrors={formularioErros}
                disabled={formularioDesativado}
            />
        </LoadingOrErrorPanel>
	)
})

export default GrupoCriacao;