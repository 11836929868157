import { useState, useEffect } from "react";

function getWindowSize() {
    return {
        larguraTotalTela: window.innerWidth,
        alturaTotalTela: window.innerHeight,
    };
}

export function useWindowSize () {
    const [dimensoesTela, setDimensoesTela] = useState(getWindowSize());

    useEffect(()=>{
        const handleResize = () => {
            setDimensoesTela(getWindowSize());
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return dimensoesTela;
};